import "react-datepicker/dist/react-datepicker.css";
import '@availity/block-ui/src/BlockUi.css';

import React, { Component } from "react";
import Lightbox from 'react-image-lightbox';
import BlockUi from '@availity/block-ui';
import Select from 'react-select';
import DatePicker from "react-datepicker";
import { components } from 'react-select';
import Creatable from 'react-select/creatable';
import Auth from '../../helpers/Auth';
import { NavLink as Link } from "react-router-dom";

import { callBackendAPI, handleFormValidation,reFormatSuccess,handleFormInputs,manageShiftTime } from './../../helpers/common';
import {STATUS_SUCCESS , TIME_PERIOD_DROPDOWN, EXPORT_TYPE_DROPDOWN, WEEK_DAYS, TIME_PICKER_FORMAT, MONTHLY, YEARLY, WEEKLY, ADMIN, DISPATCHER}  from './../../config/global_constants';
import { translate, formatTime  } from './../../helpers/intl_helpers';
import {AsyncPaginate} from 'react-select-async-paginate';

import deleteIcon 	from '../../assets/images/delete-icon.svg';


const CustomTimeInput = React.forwardRef(({ value, onClick }, ref) => (
    <input ref={ref} value={value} onClick={onClick} readOnly placeholder="Select time" className="form-control input-with-label" style={{ cursor: 'pointer' }} />
));

const customStyles = {
    menu: base => ({
      ...base,
      top: 'unset',
      bottom: '100%',
      marginTop: '-1px'
})};

const customComponents = {
    Menu: props => (
      <components.Menu {...props} />
    )
};

const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

class AddReportSettingForm extends Component {

	constructor(props) {
        super(props)

        this.state = {
			isLoading: true,
			errors: {},
			fields: {},
			submitted		:	false,
			fileUrl			:	"",
			imageSrc		:	"",
			croppedImage	:	"",
			lightBoxShow	:	false,
			fullName 		:   "",
			emailError		: 	""
        };

		this.handleFormInputs 			= this.handleFormInputs.bind(this);
		this.handleAddUserFormSubmit 	= this.handleAddUserFormSubmit.bind(this);
		this.fetchDataForEdit 			= this.fetchDataForEdit.bind(this);
		this.loadOptions 				= this.loadOptions.bind(this);

    }

	/** This function invoked immediately after a component is mounted.
	 * Its use to manage initial data fetch.
	 */
	componentDidMount() {
		this.fetchDataForEdit(this.props.editId ? this.props.editId : null);
    }

	/**
	 * This function invoked immediately after updating occurs. This method is not called for the initial render.
	 * Its call when another update subcontractor form open
	 */
	componentDidUpdate(prevProps) {
		if (this.props.editId !== prevProps.editId) {
			this.fetchDataForEdit(this.props.editId);
        }
    }

	/**
	* For load project manager options
	*
	* @param inputValue		as 	search term
	* @param loadedOptions	as 	old options array
	* @param page			as 	page count
	*
	* @return json
	*/
	async loadOptions(inputValue, loadedOptions, { page }) {
		try{
			let response = await callBackendAPI([{ model: 'calendar', method: 'employeeList', is_crew_member: true ,current_page: page, emp_name: inputValue ? inputValue : ''}]);

			let resData 		= 	response && response.data && response.data[0] ? response.data[0] :{};
			let result 			=	resData.result ? resData.result :[];
			let recordsTotal 	=	resData.recordsTotal ? resData.recordsTotal:0;
			let completeArray 	=	result.concat(loadedOptions);

			return {
				options		:	result,
				hasMore		:	completeArray.length  === recordsTotal ? false :true,
				additional	: 	{page: page + 1},
			}

		} catch (error) {
			console.error('Error loading options:', error);
			return {
				options		: 	[],
				hasMore		:	false,
				additional	: 	{page: page},
			};
		}
	}

	/**
	* For get report details for edit modal
	*
	* @param reportSettingId	as 	report setting id
	*
	* @return null
	*/
	fetchDataForEdit(reportSettingId){
		window.document.body.classList.add("modal-open");
		window.document.body.classList.add("overflow-hidden");

		if(reportSettingId){
			/** Update loading stats */
			this.setState({ isLoading : true},()=>{
				/** Get sub contactor details  */
				callBackendAPI([{ model: 'settings', method: 'reportDetails', report_setting_id: reportSettingId }]).then(response =>{
					if(response.success && response.data[0].status === STATUS_SUCCESS) {
						let reportData    =	response.data[0].result || {};
						let reportList = response.data[0].report_list;
						let userArr = [];

						if(reportData.user_details && reportData.user_details.length){
							reportData.user_details.forEach(element => {
								userArr.push({_id: element._id, value: element._id, label: element.full_name});
							});
						}

						let tmpStats = {
							isLoading	:	false,
							fields 		:	{
								report_setting_id	: { value: reportData._id || "" },
								title		 		: { value: reportData.title || "" },
								time			 	: {value: manageShiftTime(null, reportData.time)},
								time_period		 	: TIME_PERIOD_DROPDOWN.find((period) => period.value === reportData.time_period) || null,
								week_day			: WEEK_DAYS.find((day) => day.value === parseInt(reportData.week_day)) || null,
								start_date		 	: { value: reportData.start_date || "" },
								report				: reportData.reports?.map((report) =>
								reportList.find((item) => item.value === String(report))
								) || [],
								employee_ids 		: userArr,
								custom_mail_ids 	: reportData.custom_mail_ids
								? reportData.custom_mail_ids.map((email) => ({ label: email, value: email }))
								: [],
								export_type		 	: EXPORT_TYPE_DROPDOWN.find((type) => type.value === reportData.export_type) || null,
								repeat_date		 	: { value: reportData.repeat_date || "" },
							}
						};

						/** Update stats */
						this.setState(tmpStats);
					}else{
						this.closeModal();
					}
				}).catch(err => console.log(err));
			});
		}else{
			this.setState({ isLoading : false});
		}
	}//fetchDataForEdit

	/**
	* For manage form submit event
	*
	* @param e 	as 	current form object
	*
	* @return null
	*/
	handleAddUserFormSubmit(e){
		e.preventDefault();

		const {title,time,time_period,employee_ids,start_date,report,week_day,export_type, report_setting_id, custom_mail_ids,repeat_date} = this.state.fields;

		console.log("custom_mail_ids",custom_mail_ids)

		let empArr   	= [];
		let reportArr   = [];
		if (employee_ids && employee_ids.length) empArr = employee_ids.map(element => element.value);
		if (report && report.length) reportArr = report.map(element => element.value);

		let emailArr = [];
		if (custom_mail_ids && custom_mail_ids.length) {
			emailArr = custom_mail_ids.map(mail => mail.value);
		}

		this.setState({
			submitted: true,
			fields : {
                ...this.state.fields,
                ...{
                    employees: {value: empArr.length ? empArr : null},
                    reports_val: {value: reportArr.length ? reportArr : null},
                }
            }
		 },()=>{

			/** Api Requested Data **/
			let params = [{
				model			: 	'settings',
				method			: 	'addEdit',
				employees		:	empArr,
				reports			:   reportArr,
				title			: 	(title && title.value)					?	title.value				:"",
				time			: 	(time && time.value)					?	formatTime(time.value,"HH:mm")				:"",
				time_period		: 	(time_period && time_period.value)		?	time_period.value		:"",
				week_day		: 	(week_day && week_day.value)			?	week_day.value			:"",
				start_date		: 	(start_date && start_date.value)		?	start_date.value		:"",
				repeat_date		: 	(repeat_date && repeat_date.value)		?	repeat_date.value		:"",
				export_type		: 	(export_type && export_type.value)		?	export_type.value		:"",
				custom_mail_ids: 	emailArr,
				report_setting_id: 	(report_setting_id && report_setting_id.value)	?	report_setting_id.value	:"",
			}];

			/** Validation Rules **/
			let validations = {
				title		: {"label": translate('title','Title'), "rules" :["required"]},
				time		: {"label": translate('time','Time'), "rules" :["select"]},
				time_period	: {"label": translate('time_period','Time Period'), "rules" :["select"]},
				start_date	: {"label": translate('start_date','Start Date'), "rules" :["select"]},
				export_type	: {"label": translate('export_type','Export Type'), "rules" :["select"]},
				employees	: {"label": translate('employees','Employees'), "rules" :["select"]},
				reports_val	: {"label": translate('reports_val','Reports'), "rules" :["select"]},
			}

			if(time_period && time_period.value && (time_period.value === YEARLY || time_period.value === MONTHLY)){
				validations['repeat_date'] = {"label": translate('repeat_date','Repeat Date'), "rules" :["required"]};
			}
			if(time_period && time_period.value && (time_period.value === WEEKLY)){
				validations['week_day'] = {"label": translate('week_day','Week Day'), "rules" :["select"]};
			}

			/** Handle validation and api response **/
			handleFormValidation(validations, this.state.fields, params, true).then(async (response) =>{
				if(!response.success) return this.setState({ errors : response.errors, submitted: false});

				/** Show success flash message **/
				reFormatSuccess(response.data.message[0].msg);

				/** Remove disable attribute from submit button */
				this.setState({ submitted: false, errors: {} });

				/** Call the function to fetch employee list */
				if(this.props.getTableList) {
					this.props.getTableList();
				}
				/** Close the modal */
				this.closeModal();

			}).catch(err => console.log(err));
		});
	}

	/**
	* For hide model
	*
	* @return null
	*/
	closeModal() {
		window.document.body.classList.remove("modal-open");
		window.document.body.classList.remove("overflow-hidden");

		this.props.toggleModal(false);
	}// end closeModal()

	/**
	* For update state fields values
	*
	* @param e  	as current field object
	* @param field  as current field name
	*
	* @return null
	*/
	handleFormInputs(e, field){
		let response	=	handleFormInputs(e, field, this.state.fields);
		this.setState({ response });
	}// end handleFormInputs()


	handleChange = (fieldName) => (fieldVal) => {
		if(fieldName === "time") 	fieldVal = {value: fieldVal};
        this.setState({
            fields: {
                ...this.state.fields,
                [fieldName]: fieldVal
            }
        });
    };

	handleEmailChange = (value) => {
        // Check if the value is valid
        if (value && value.length > 0) {
            const invalidEmails = value.filter((option) => !emailRegex.test(option.value));

            if (invalidEmails.length > 0) {
                this.setState({ emailError: "Please enter valid email addresses." });
                return; // Prevent invalid emails from being added
            } else {
                this.setState({ emailError: "" }); // Clear error if all emails are valid
            }
        }

        // Update state with the valid values
        this.setState({
            fields: {
                ...this.state.fields,
                custom_mail_ids: value,
            },
        });
    };

	render() {
		const {handleDelete} = this.props;
		const {submitted,fields,errors,fileUrl,isLoading,lightBoxShow,emailError} = this.state;
		const reportSettingId = (fields && fields.report_setting_id && fields.report_setting_id.value) ? fields.report_setting_id.value : "";

		return <>
			<div
				className={`modal fade show d-block report-modal common-modal ${lightBoxShow ? 'zIndex-100' : ''}`}
				id="adduserModal"
				tabIndex={-1}
				aria-labelledby="adduserModal"
				aria-hidden="true"
			>
				<div className="modal-dialog modal-xl">
					<div className="modal-content">
						<button
							type="button"
							className="btn-close"
							data-bs-dismiss="modal"
							aria-label="Close"
							onClick={() => this.closeModal()}
						>
							X {translate("system.close")}
						</button>
						<div className="modal-body">
							<BlockUi tag="div" blocking={isLoading}>
								<h1 className="modal-title">{reportSettingId ? translate("calendar.edit") : translate("employee.add_employee")}</h1>
								<form  className="adduser-form" onSubmit={(event) => this.handleAddUserFormSubmit(event, 'addUser')} noValidate>
									<div className="row">
										<div className="col-lg-6">
											<div className="custom-form-group form-group mb-3">
												<label className="mb-1 form-label" >{translate("settings.title")}<span className="error text-danger">*</span></label>
												<input
													id="title"
													type="text"
													name="title"
													className="form-control input-with-label"
													placeholder={translate("settings.title")}
													defaultValue={fields["title"] ? fields["title"]["value"] : ""}
													onChange={(event) => this.handleFormInputs(event, "title")}
												/>
												<span className="error text-danger">{errors["title"]}</span>
											</div>
										</div>
										<div className='col-lg-6'>
											<div className="custom-form-group form-group mb-3 mb-3">
												<label className="mb-1 form-label" >{translate("settings.time")}<span className="error text-danger">*</span></label>
												<DatePicker
													selected={fields.time && fields.time.value ? fields.time.value : null}
													onChange={this.handleChange('time')}
													showTimeSelect
													showTimeSelectOnly
													timeIntervals={1}
													timeCaption={translate("calendar.time")}
													dateFormat={TIME_PICKER_FORMAT}
													timeFormat={TIME_PICKER_FORMAT}
													className="form-control input-with-label"
													placeholderText={translate('calendar.time')}
													customInput={<CustomTimeInput />}
												/>
												<span className="error text-danger">{errors["time"]}</span>
											</div>
										</div>
										<div className='col-lg-6'>
											<div className=" custom-form-group form-group mb-3">
													<label className="mb-1 form-label">{translate("settings.time_period")}<span className="error text-danger">*</span></label>
													<Select
														className="custom-react-select "
														placeholder={<label htmlFor={"time_period"}>{translate("settings.select_time_period")}</label>}
														options={TIME_PERIOD_DROPDOWN}
														value={fields["time_period"] ? fields["time_period"] : null}
														closeMenuOnSelect={true}
														onChange={this.handleChange("time_period")}
													/>
													<span className="error text-danger">{errors["time_period"]}</span>
											</div>
										</div>
										{(fields["time_period"] && (fields["time_period"]["value"] === "monthly" || fields["time_period"]["value"] === "yearly")) &&
											<div className='col-lg-6'>
												<div className="custom-form-group form-group mb-3 mb-3">
													<label className="mb-1 form-label" >{translate("settings.repeat_date")}<span className="error text-danger">*</span></label>
													<DatePicker
														className="form-control input-with-label"
														selected={fields.repeat_date && fields.repeat_date.value ? fields.repeat_date.value :null}
														onChange={(date) => {this.setState({ fields : {...fields, ...{repeat_date:{value: date} }} }) }}
														placeholderText={translate("settings.select_repeat_date")}
														dropdownMode="select"
														showMonthDropdown
														showYearDropdown
													/>
													<span className="error text-danger">{errors["repeat_date"]}</span>
												</div>
											</div>
										}
										{(fields["time_period"] && (fields["time_period"]["value"] === "weekly")) &&
											<div className='col-lg-6'>
												<div className="custom-form-group form-group mb-3">
													<div>
														<label className="mb-1 form-label" htmlFor="week_day">{translate("settings.week_day")}<span className="error text-danger">*</span></label>
														<Select
															className="custom-react-select"
															placeholder={<label htmlFor={"week_day"}>{translate("settings.select_week_day")}</label>}
															options={WEEK_DAYS}
															value={fields["week_day"] ? fields["week_day"] : null}
															closeMenuOnSelect={true}
															onChange={this.handleChange("week_day")}
														/>
														<span className="error text-danger">{errors["week_day"]}</span>
													</div>
												</div>
											</div>
										}
										<div className='col-lg-6'>
											<div className="custom-form-group form-group mb-3 mb-3">
												<label className="mb-1 form-label" >{translate("settings.starting")}</label>
												<DatePicker
													className="form-control input-with-label"
													selected={fields.start_date && fields.start_date.value ? fields.start_date.value :null}
													onChange={(date) => {this.setState({ fields : {...fields, ...{start_date:{value: date} }} }) }}
													placeholderText={translate("settings.select_start_date")}
													dropdownMode="select"
													showMonthDropdown
													showYearDropdown
												/>
												<span className="error text-danger">{errors["start_date"]}</span>
											</div>
										</div>
										<div className='col-lg-6'>
											<div className="custom-form-group form-group mb-3 bulk-item-lists">
												<div>
													<label className="mb-1 form-label" htmlFor="employees">{translate("settings.employees")}<span className="error text-danger">*</span></label>
													<AsyncPaginate
														className="custom-react-select"
														value={fields.employee_ids ? fields.employee_ids : null}
														loadOptions={this.loadOptions}
														onChange={this.handleChange("employee_ids")}
														placeholder={<label htmlFor="employee_ids">{translate("employee.select_employees")}</label>}
														additional={{ page: 1 }}
														isMulti
													/>
													<span className="error text-danger">{errors["employees"]}</span>
												</div>
											</div>
										</div>
										<div className='col-lg-6'>
											<div className="custom-form-group form-group mb-3">
												<div>
													<label className="mb-1 form-label" htmlFor="employees">{translate("settings.add_manual_emails")}</label>
													<Creatable
														className="custom-react-select"
														value={fields.custom_mail_ids ? fields.custom_mail_ids : null}
														options={[]}
														onChange={this.handleEmailChange}
														closeMenuOnSelect={true}
														placeholder={
															<label htmlFor="custom_mail_ids">
																{translate("settings.add_manual_emails")}
															</label>
														}
														styles={customStyles}
														components={customComponents}
														isMulti
													/>
													{emailError && (
														<p style={{ color: "red", fontSize: "12px", marginTop: "5px" }}>
															{emailError}
														</p>
													)}
													<span className="error text-danger">{errors["custom_mail_ids"]}</span>
												</div>
											</div>
										</div>
										<div className='col-lg-6'>
											<div className=" custom-form-group form-group mb-3 bulk-item-lists">
												<div>
													<label className="mb-1 form-label">{translate("settings.report")}<span className="error text-danger">*</span></label>
													<Select
														className="custom-react-select"
														placeholder={<label htmlFor={"report"}>{translate("settings.select_report")}</label>}
														options={this.props.reportDropdown}
														value={fields["report"] ? fields["report"] : null}
														closeMenuOnSelect={true}
														onChange={this.handleChange("report")}
														isMulti
													/>
													<span className="error text-danger">{errors["reports"]}</span>
													<span className="error text-danger">{errors["reports_val"]}</span>
												</div>
											</div>
										</div>
										<div className='col-lg-6'>
											<div className=" custom-form-group form-group mb-3">
												<div>
													<label className="mb-1 form-label" htmlFor="search_by_department">{translate("settings.export_type")}<span className="error text-danger">*</span></label>
													<Select
														className="custom-react-select"
														placeholder={<label htmlFor={"export_type"}>{translate("settings.select_export_type")}</label>}
														options={EXPORT_TYPE_DROPDOWN}
														value={fields["export_type"] ? fields["export_type"] : null}
														closeMenuOnSelect={true}
														onChange={this.handleChange("export_type")}
													/>
													<span className="error text-danger">{errors["export_type"]}</span>
												</div>
											</div>
										</div>
									</div>
									<div className="employee-submit d-flex flex-wrap align-items-center justify-content-between">
										<div className="left-box d-flex flex-wrap align-items-center">
											<button type={submitted ? "button" : "submit"} className="btn btn-fill width-initial mt-0">
												{translate("system.submit")}
											</button>
											<div><button type="button" className="btn btn-outline" onClick={()=>{this.props.toggleModal(false)}}>{translate("system.cancel")}</button></div>
										</div>
										{
										// Check if the user is not a project manager or foreman
										([ADMIN, DISPATCHER].indexOf(Auth.getPermission()) !== -1) ?
											// Check if an employee ID exists
											(reportSettingId ?
											<div className="right-box">
												<Link
												to="#"
												className="details_delete_icon btn btn-outline"
												onClick={() => handleDelete(reportSettingId)}
												>
												<img src={deleteIcon} alt="img" width={19} height={21}/>
													Delete
												</Link>
											</div>
											: null)
										: null}
									</div>
								</form>
							</BlockUi>
						</div>
					</div>
				</div>
			</div>

			{!lightBoxShow &&
				<div className="modal-backdrop fade show"></div>
			}
			{
				lightBoxShow &&
				<Lightbox
					mainSrc={fileUrl+fields.image.value}
					onCloseRequest={() => this.setState({ lightBoxShow: false })}
				/>
			}

		</>
	}
}
export default AddReportSettingForm;