import React, { Component } from "react";
import { translate } from './../helpers/intl_helpers';
import MapModal from './../components/MapModal';

let googleMapsScriptLoaded = false;

class GoogleMap extends Component {
	constructor(props) {
        super(props);

        this.state = {
			map: null,
      		markers: [],
			showModal: false,
			jobDetails    : "",
			isJobDetail   : this.props.isJobDetail || false,
        };

		this.mapRef = React.createRef();
    }

	componentDidMount() {
		// Load the Google Maps script dynamically
		this.loadGoogleMapsScript();
	}

	componentWillUnmount() {
		// Clean up markers and InfoWindows
		this.state.markers.forEach(marker => {
			if (marker.infoWindow) {
				marker.infoWindow.close();
			}
			marker.setMap(null);
		});
	}

	loadGoogleMapsScript() {
		if (googleMapsScriptLoaded) {
			this.initMap();
			return;
		}

		// Create the google script element
		googleMapsScriptLoaded = true;
		this.initMap();
		window.initMap = this.initMap;
	}

	initMap = () => {
		// Initialize the map with a fixed center and zoom
		let mapInstance = new window.google.maps.Map(this.mapRef.current, {
			center: { lat: 47.6061, lng: -122.60321 }, // Fixed center (e.g., Seattle, WA)
			zoom: 8, // Fixed zoom level
			mapTypeControl: false,
			streetViewControl: false,
		});

		this.setState({ map: mapInstance });
	};

	// Function to add a marker with job data
	geocodeLatLng(lat, lng, title, jobData) {
		const google = window.google;

		const location = new google.maps.LatLng(lat, lng); // Use lat/lng directly
		this.addMarker(location, title, jobData);
		this.state.map.setCenter(location);
	}

	// Function to add a marker with infoWindow
	addMarker(location, title, jobData) {
		const google = window.google;
		const marker = new google.maps.Marker({
			position: location,
			map: this.state.map, // Use the initialized map
			title: title ? String(title) : "",
		});

		// Add click event for InfoWindow or modal
		marker.addListener("click", () => {
			this.openModal(jobData);
		});

		// Save marker to the state
		this.setState((prevState) => ({
			markers: [...prevState.markers, marker],
		}));
	}

	// Method to initialize markers from props
	markerInitiated() {
		// Clear existing markers
		if (this.state.markers && this.state.markers.length) {
			this.state.markers.forEach((marker) => {
				marker.setMap(null); // Remove marker from the map
			});
		}

		this.setState({ markers: [] }, () => {
			// Dynamically add markers without changing the map center
			if (this.props.dataList && this.props.dataList.length) {
				this.props.dataList.forEach((element) => {
					this.addMarker(
						{ lat: element.latitude, lng: element.longitude }, // Marker position
						`Total Jobs - ${element.count}`, // Marker title
						element.jobList // Job data for modal
					);
				});
			}
		});
	}

	// Open the modal with the selected job data
	openModal = (jobData) => {
		this.setState({ showModal: true, jobDetails: jobData },()=>{
			window.openModal('mapModal');
		});
	};

	// Close the modal
	closeModal = () => {
		this.setState({ showModal: false, jobDetails: null });
	};

	render() {
		const { showModal, jobDetails, isJobDetail } = this.state;

		return (
			<div className="row">
				<div className="col-12">
					<div ref={this.mapRef} id="googleMapSection" className='w-100 h-500'></div>
				</div>
				{showModal && !isJobDetail && jobDetails && jobDetails.length && <MapModal jobDetails={jobDetails} closeModal={this.closeModal} />}
			</div>
		)
	}
}

export default GoogleMap;
