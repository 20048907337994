import React, { Component } from "react";
import Lightbox from 'react-image-lightbox';
import { translate, formatDate, formatTime  } from '../../../helpers/intl_helpers';
import { upperCaseOfName  } from '../../../helpers/common';
import { DATERANGE_DATE_TIME_FORMAT, DEPARTMENT_DROPDOWN, JOB_TITLE_TYPE_DROPDOWN, ACTIVE } from '../../../config/global_constants'

import callIcon 	from '../../../assets/images/call-icon.svg';
import mailIcon 	from '../../../assets/images/mail-icon.svg';

export default class AssetDetailModal extends Component {
	constructor(props){
        super(props)
        this.state = {};
    }

	/**
	* For hide model
	*
	* @return null
	*/
	closeModal(){
        window.closeModal('assetDetailsModal');
	}// end closeModal()

	/** Function to handle image lightbox */
	handleImageClick = (imageUrl) => {
		this.setState({
			lightBoxShow	: true,
			selectedImage	: imageUrl
		});
	}


	render() {
		const { assetDetails } = this.props;
		const { lightBoxShow, selectedImage } = this.state;
		let detailType = assetDetails && assetDetails.state && assetDetails.state.type ? assetDetails.state.type : "";
		let shopDetails = assetDetails && assetDetails.state && assetDetails.state.shop_details ? assetDetails.state.shop_details : "";
		let assetNotes = shopDetails && shopDetails.notes && shopDetails.notes.length ? shopDetails.notes[0] : "";

		let detailName = assetDetails && assetDetails.name ? assetDetails.name : "N/A";
		if(detailType === "asset") detailName = assetDetails.state.equipment_code ? assetDetails.state.equipment_code : "N/A";

		return (
			<>
			<div className="modal fade  crew-modal" id="assetDetailsModal" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
				<div className="modal-dialog modal-xl">
					<div className="modal-content">
						<button type="button" className="btn-close" onClick={()=> {this.closeModal()} }>X Close</button>
						<div className="modal-body">
							<h3>{detailName}</h3>
							{(detailType === "asset") ?
								(<div className="list-group list-group-flush">
									<ul className="details-listing">
										<li>
											<div className="profile-detail-box">
												<span className={`sort-name ${(!assetDetails.state.image) ? 'greenbg' : ''}`}>
													{assetDetails.state && assetDetails.state.image ? (
														<img src={assetDetails.state.image} alt="employee" onClick={() => this.handleImageClick(assetDetails.state.image)} />
													) : (
														upperCaseOfName(assetDetails.state.first_name ? assetDetails.state.first_name : "", assetDetails.state.last_name ? assetDetails.state.last_name : "")
													)}
												</span>
												<div>
													<div className="inner-wrap">
														<p>{translate('employee.equipment_code')}</p>
														<span>{assetDetails.state.equipment_code ? assetDetails.state.equipment_code : "N/A"}</span>
														<span className={assetDetails.state.is_active ? "text-success" : "text-danger"}>
															{assetDetails.state.is_active ? "Active" : "Deactive"}
														</span>
													</div>
												</div>
											</div>
										</li>
										<li>
											<div className="inner-wrap">
												<p>{translate('equipments.equipment_type')}</p>
												<span>{assetDetails.state.equipment_type ? assetDetails.state.equipment_type : "N/A"}</span>
											</div>
										</li>
										<li>
											<div className="inner-wrap">
												<p>{translate('equipments.description')}</p>
												<span>{assetDetails.state.description ? assetDetails.state.description : "N/A"}</span>
											</div>
										</li>
										<li>
											<div className="inner-wrap">
												<p>{translate('equipments.equipment_make_model')}</p>
												<span>{assetDetails.state.equipment_make && assetDetails.state.equipment_model  ? assetDetails.state.equipment_make +' / '+ assetDetails.state.equipment_model : assetDetails.state.equipment_make ? assetDetails.state.equipment_make : assetDetails.state.equipment_model ? assetDetails.state.equipment_model : ""}</span>
											</div>
										</li>
										<li>
											<div className="inner-wrap">
												<p>{translate('equipments.location')}</p>
												<span>{assetDetails.state.location_title ? assetDetails.state.location_title : ""}</span>
											</div>
										</li>
										<li>
											<div className="inner-wrap">
												<p>{translate('equipments.department')}</p>
												<span>
													{(assetDetails.state.department && assetDetails.state.department.length) &&
														DEPARTMENT_DROPDOWN.filter(department => assetDetails.state.department.includes(department.label.toLowerCase()))
														.map(department => department.label)
														.join(',')
													}
												</span>
											</div>
										</li>
										<li>
											<div className="inner-wrap">
												<p>{translate('employee.assignee_name')}</p>
												<span>{assetDetails.state.driver_owner_name ? assetDetails.state.driver_owner_name : "N/A"}</span>
											</div>
										</li>
										<li>
											<div className="inner-wrap">
												<p>{translate('equipments.rental_status')}</p>
												<span>{assetDetails.state.is_rental === ACTIVE ? "Yes" : "No"}</span>
											</div>
										</li>
										<li>
											<div className="inner-wrap">
												<p>{translate('equipments.company_code')}</p>
												<span>{assetDetails.state.company_code ? assetDetails.state.company_code : "N/A"}</span>
											</div>
										</li>
										<li>
											<div className="inner-wrap">
												<p>{translate('equipments.tier_level')}</p>
												<span>{assetDetails.state.tier_name ? assetDetails.state.tier_name : "N/A"}</span>
											</div>
										</li>
										<li>
											<div className="inner-wrap">
												<p>{translate('equipments.year')}</p>
												<span>{assetDetails.state.year ? assetDetails.state.year : "N/A"}</span>
											</div>
										</li>
										<li>
											<div className="inner-wrap">
												<p>{translate('equipments.serial_number')}</p>
												<span>{assetDetails.state.serial_number ? assetDetails.state.serial_number : "N/A"}</span>
											</div>
										</li>
										<li>
											<div className="inner-wrap">
												<p>{translate('equipments.license_number')}</p>
												<span>{assetDetails.state.license_number ? assetDetails.state.license_number : "N/A"}</span>
											</div>
										</li>
										<li>
											<div className="inner-wrap">
												<p>{translate('equipments.gvw_class')}</p>
												<span>{assetDetails.state.gvw_class ? assetDetails.state.gvw_class : "N/A"}</span>
											</div>
										</li>
										{assetNotes &&
											<>
												<li>
													<div className="inner-wrap">
														<p>{translate('calendar.last_updated_by')}</p>
														<span>{assetNotes && assetNotes.created_by ? assetNotes.created_by : "N/A"}</span>
													</div>
												</li>
												<li>
													<div className="inner-wrap">
														<p>{translate('calendar.created_at')}</p>
														<span>{assetNotes && assetNotes.created ? formatDate(assetNotes.created,DATERANGE_DATE_TIME_FORMAT) : "N/A"}</span>
													</div>
												</li>
												<li className="w-100 reason-box">
													<div className="inner-wrap">
														<p>{translate('calendar.maintenance')}</p>
														<span className="nl2br">{assetNotes && assetNotes.notes ? assetNotes.notes : "N/A"}</span>
													</div>
												</li>
											</>
										}
									</ul>
								</div>) :
								(assetDetails.state ?
									<div className="list-group list-group-flush">
										<ul className="details-listing">
											<li>
												<div className="profile-detail-box">
													<span className={`sort-name ${(!assetDetails.state.image) ? 'greenbg' : ''}`}>
														{assetDetails && assetDetails.state.image ? (
															<img src={assetDetails.state.image} alt="employee" onClick={() => this.handleImageClick(assetDetails.state.image)} />
														) : (
															upperCaseOfName(assetDetails.state.first_name ? assetDetails.state.first_name : "", assetDetails.state.last_name ? assetDetails.state.last_name : "")
														)}
													</span>
													<div>
														<div className="inner-wrap">
															<p>{translate('employee.employee_number')}</p>
															<span>{assetDetails.state.employee_number ? assetDetails.state.employee_number : "N/A"}</span>
															<ul className="d-flex align-items-center termination-list">
																{assetDetails.state.active ?
																	<li>
																		<span className="">{assetDetails.state.hire_date ? formatDate(assetDetails.state.hire_date , DATERANGE_DATE_TIME_FORMAT) : "N/A"}</span>
																	</li>
																: <li>
																	<span className="">{assetDetails.state.termination_date ? formatDate(assetDetails.state.termination_date , DATERANGE_DATE_TIME_FORMAT) : "N/A"}</span>
																	</li>
																}
																<li>
																	<span className={assetDetails.state.active ? "text-success" : "text-danger"}>
																		{assetDetails.state.active ? translate("employee.active") : translate("employee.terminated")}
																	</span>
																</li>

															</ul>
														</div>
													</div>
												</div>
											</li>
											<li>
												<div className="inner-wrap">
													<p>{translate('employee.company_name')}</p>
													<span>{assetDetails.state.company_name_title ? assetDetails.state.company_name_title : "N/A"}</span>
												</div>
											</li>
											<li>
												<div className="inner-wrap">
													<p>{translate('employee.email')}</p>
													<div>
														<img src={mailIcon} alt="img" width={19} height={21} />
														{assetDetails.state.email ? (
															<a href={`mailto:${assetDetails.state.email}`} className="text-lowercase email-link">
																{assetDetails.state.email}
															</a>
														) : "N/A"}
													</div>
												</div>
											</li>
											<li>
												<div className="inner-wrap">
													<p>{translate('subcontractor.mobile')}</p>
													<div>
														<img src={callIcon} alt="img" width={19} height={21} />
														{assetDetails.state.mobile ? (
															<a href={`tel:${assetDetails.state.mobile}`}>{assetDetails.state.mobile}</a>
														) : "N/A"}
													</div>
												</div>
											</li>
											<li>
												<div className="inner-wrap">
													<p>{translate('employee.location')}</p>
													<span>{assetDetails.state.location_title ? assetDetails.state.location_title : "N/A"}</span>
												</div>
											</li>
											<li>
												<div className="inner-wrap">
													<p>{translate('employee.department')}</p>
													<span>
														{(assetDetails.state.department && assetDetails.state.department.length) &&
															DEPARTMENT_DROPDOWN.filter(department => assetDetails.state.department.includes(department.label.toLowerCase()))
															.map(department => department.label)
															.join(',')
														}
													</span>
												</div>
											</li>
											<li>
												<div className="inner-wrap">
													<p>{translate('employee.assigned_vehicle')}</p>
													<span>{assetDetails.state.assigned_vehicle ? assetDetails.state.assigned_vehicle : "N/A"}</span>
												</div>
											</li>
											<li>
												<div className="inner-wrap">
													<p>{translate('employee.job_title_type')}</p>
													<span>{JOB_TITLE_TYPE_DROPDOWN.find(item => item.value === assetDetails.state.job_title_type)?.label || "N/A"}</span>
												</div>
											</li>
											<li>
												<div className="inner-wrap">
													<p>{translate('employee.driver_tier_level')}</p>
													<span>{assetDetails.state.driver_tier_level ? assetDetails.state.driver_tier_level : "N/A"}</span>
												</div>
											</li>
											<li>
												<div className="inner-wrap">
													<p>{translate('employee.bpid')}</p>
													<span className="text-transform-none">{assetDetails.state.bpid ? assetDetails.state.bpid : "N/A"}</span>
												</div>
											</li>
										</ul>
									</div> : null
								)
							}
						</div>
					</div>
				</div>
			</div>
			{
				lightBoxShow &&
				<Lightbox
					mainSrc={selectedImage}
					onCloseRequest={() => this.setState({ lightBoxShow: false })}
				/>
			}
			</>
		)
	}
}
