
import React, {Component} from 'react'
import { translate, formatDate } from '../../helpers/intl_helpers'

import { handleFormValidation, callBackendAPI } from '../../helpers/common';
import { STATUS_SUCCESS, DATERANGE_DATE_TIME_FORMAT } from '../../config/global_constants';


import checkIcon from '../../assets/images/check_icon.svg';


class DeleteModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            jobId : "",
            type : this.props.type,
            foremanId : this.props.foremanId,
            memberAsseId : this.props.memberAsseId,
            jobOptions : this.props.jobOptions,
            assignMemberAssetForJob : this.props.assignMemberAssetForJob,
            fields  : {},
            errors  : {},
            deletedJobGroupId: this.props.deletedJobGroupId,
            jobList: [],
            selectedJobIds: [],
            selectAll: false,
        };
    }

    componentDidMount(){
        this.getJobList();
    }

    /**
	* For get job listing
	*/
    getJobList = () =>{
        const { deletedJobGroupId } = this.state;

		/** Set API request params */
        callBackendAPI([{model: 'jobs', method: 'getJobList', job_group_id: deletedJobGroupId}]).then(response => {
           if(response.success && response.data[0].status === STATUS_SUCCESS && response.data[0].result) {
                this.setState({jobList: response.data[0].result});
            }
       }).catch(err => console.log(err));
   }// end getJobList()

   /**
	* For manage form submit event
	*
	* @param e as current form object
	*
	* @return null
	*/
	handleFormSubmit(e){
		e.preventDefault();
        let { selectedJobIds } = this.state;

		this.setState({
            submitted: true,
            fields : {
                ...this.state.fields,
                ...{
                    job_id: {value: selectedJobIds.length ? selectedJobIds : null},
                }
            }
        },()=>{
            /** Api Requested Data **/
            let params = [{
                model		: 	'jobs',
                method		: 	'deleteJob',
                job_id      :   selectedJobIds,
            }];

            /** Validation Rules **/
            let validations = {job_id	: {"label": translate('calendar.jobs'), "rules" :["select"]}}

            /** Handle validation and api response **/
            handleFormValidation(validations,this.state.fields, params,true , true, true ).then(response =>{
                if(!response.success) return this.setState({submitted: false, errors : response.errors});

                this.props.handleCloseTaskModal();
                this.props.updateEventCalendar(null,"calendar");
                /** Remove disable attribute from submit button */
                this.setState({ errors: {} , fields: {} , submitted: false});

            }).catch(err => console.log(err));
        });
	}

    handleCheckboxChange = (jobId) => (event) => {
        const { selectedJobIds } = this.state;
        if (event.target.checked) {
            this.setState({ selectedJobIds: [...selectedJobIds, jobId] });
        } else {
            this.setState({ selectedJobIds: selectedJobIds.filter(id => id !== jobId), selectAll: false });
        }
    };

    handleSelectAllChange = (event) => {
        const { jobList } = this.state;
        if (event.target.checked) {
            this.setState({
                selectAll: true,
                selectedJobIds: jobList.map(job => job._id)
            });
        } else {
            this.setState({
                selectAll: false,
                selectedJobIds: []
            });
        }
    };

    render() {
        const {submitted, fields, errors, jobList, selectedJobIds, selectAll} = this.state;

        return <div className="modal fade crew-modal job-detail-modal" id="deleteModal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="asSubContractorJobLabel" aria-hidden="true">
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <button type="button" className="btn-close" onClick={()=>{this.props.handleCloseTaskModal()}}>X Close</button>
                    <div className="modal-body">
                          <h3>
                          {translate('calendar.delete_jobs')}
                        </h3>
                        <form onSubmit={(event) => this.handleFormSubmit(event)} noValidate>
                            <div className="form-check all-selector-box">
                                <input
                                    type="checkbox"
                                    className="form-check-input"
                                    id="all_selector"
                                    checked={selectAll}
                                    onChange={this.handleSelectAllChange}
                                />
                                <label className="form-check-label" htmlFor="all_selector">
                                {translate('calendar.select_all')}
                                </label>
                            </div>
                            <ul className='job-detail-listing'>
                                {jobList.map(job => (
                                    <li className="" key={job._id}>
                                            <div className="form-check" key={job._id}>
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    id={`job_${job._id}`}
                                                    onChange={this.handleCheckboxChange(job._id)}
                                                    checked={selectedJobIds.includes(job._id)}
                                                />
                                                <label className="form-check-label" htmlFor={`job_${job._id}`}>
                                                    <div className="jobs-date">{job.date ? formatDate(job.date, DATERANGE_DATE_TIME_FORMAT) : "N/A"}</div>
                                                    <span>{job.job_number ? job.job_number : "N/A"}</span>
                                                    <p>{job.manager_name ? job.manager_name : "N/A"}</p>
                                                    <div className="jobs-check">
                                                        <img src={checkIcon} alt="Copy icon" />
                                                    </div>
                                                </label>
                                            </div>
                                    </li>
                                ))}
                            </ul>
                            <span className="error text-danger mb-3 d-block">{errors["job_id"]}</span>
                            <div className='col-xl-12 d-flex'>
                                <button type={submitted ? "button" :"submit"} className="btn btn-fill">
                                    {translate('system.delete')}{submitted && <i className="spinner-border spinner-border-sm  ms-3"/>}
                                </button>
                                <button type="button" className="btn btn-outline ms-3" onClick={()=>{this.props.handleCloseTaskModal()}} data-dismiss="modal">
                                    {translate('system.close')}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    }
}

export default DeleteModal
