import React, { Component } from "react";
import { callBackendAPI, handleFormInputs,customConfirmBox,reFormatSuccess, setOrGetCookiesValue} from '../../helpers/common';
import { STATUS_SUCCESS , PAGING_LENGTH_MENU, ACTIVE,INACTIVE, ADMIN, DISPATCHER,DATERANGE_DATE_TIME_FORMAT}  from '../../config/global_constants';
import { translate, formatDate } from './../../helpers/intl_helpers';
import { NavLink as Link} from "react-router-dom";
import EllipsisPagination from './../Pagination';
import { Table } from 'react-bootstrap';
import Select from 'react-select';
import Auth from './../../helpers/Auth';

import AddForm from './AddReportSetting';
import ReportSettingDetailModal from './ReportSettingDetailModal';

import closeIcon 	from '../../assets/images/close_icon.svg';
import filterImg 	from '../../assets/images/filter.svg';
import loaderGif 	from '../../assets/images/pagination_loader.gif';
import plusIcon    from '../../assets/images/plus_icon.svg';

class ReportSettingList extends Component {

	constructor(props) {
        super(props);

		/** Get search stats from cookies  */
		let tmpSearchStats = {status: { value: ACTIVE, label: "Active" }};
		let tmpVal =  setOrGetCookiesValue('searchStatsReportSettingList');
		if(tmpVal && tmpVal.constructor === Object && Object.keys(tmpVal).length) tmpSearchStats = tmpVal;

        this.state = {
			isLoading:	true,
			fields   : {},
			searchStats: tmpSearchStats,
			showModel: false,
            reportList : [],
            editId: null,
			isAddEmployeeFormVisible: false,
			fileUrl : "",
			noImgUrl: "",
			currentPage: 1,
			totalRecords: 0,
			totalPages	: 0,
			rowsPerPage	: PAGING_LENGTH_MENU[0],
			sortStats	:	{},
			showDetailModal: false,
			profileDetails    : "",
			reportDropdown 	: ""
        };

		this.toggleModal 			  = this.toggleModal.bind(this);
		this.getTableList    		  =	this.getTableList.bind(this);
		this.handleChangePage    	  =	this.handleChangePage.bind(this);
		this.resetSearchStats    	  =	this.resetSearchStats.bind(this);
		this.handlePageLengthChange	  =	this.handlePageLengthChange.bind(this);
		this.handleDelete 	  		  =	this.handleDelete.bind(this);
    }

	/** This function invoked immediately after a component is mounted.
	 * Its use to call initial function.
	 */
	componentDidMount() {
		this.getTableList();
    }

	/** Manage add / update modal box event */
	toggleModal(close , editId) {
		window.closeModal('reportSettingDetailModal');
		this.setState({
			showModel: close,
			editId   : editId
		});
	}

	/**
	* For update search state fields values
	*
	* @param fieldName	as current field name
	* @param fieldVal  	as current field value
	*
	* @return null
	*/
	handleChange = (fieldName) => (fieldVal) => {
		this.setState({
			searchStats: {
				...this.state.searchStats,
				[fieldName]: fieldVal
			}
		});
	};

	/**
	* For manage page records limit stats
	*
	* @param lengthOpt as selected limit option params
	*
	* @return null
	*/
	handlePageLengthChange = (lengthOpt) => {
		if(lengthOpt && lengthOpt.value && !isNaN(lengthOpt.value)){
			this.setState({
				rowsPerPage : lengthOpt.value,
				currentPage	: 1,
			},()=>{
				this.getTableList();
			});
		}
	};

	/**
	* For update current page stats
	*
	* @param newPage as selected page number
	*
	* @return null
	*/
	handleChangePage = (newPage = 1,e) => {
		if(e) e.preventDefault();
		this.setState({currentPage: newPage},()=>{
			this.getTableList();
		});
	};

	/**
	* For reset search stats
	*
	* @param null
	*
	* @return null
	*/
	resetSearchStats = () => {
		this.setState({
			searchStats	: {},
			currentPage	: 1,
		},()=>{
			this.getTableList();
		});
	};

	/**
	* For update state fields values
	*
	* @param e  	as current field object
	* @param field  as current field name
	*
	* @return null
	*/
	handleFormInputs(e, field){
		let response	=	handleFormInputs(e, field, this.state.searchStats);
		this.setState({response});
	}// end handleFormInputs()

	/**
	 * Manage sort event
	 */
	manageSortingEvent = (event, field, dir) => {
		event.preventDefault();

		this.setState({
			sortStats	: {key: field, dir: dir},
			currentPage	: 1,
		},()=>{
			this.getTableList();
		});
	};

	/**
	* For fetch subcontractor listing
	*/
	getTableList = () => {
		const { rowsPerPage, currentPage, searchStats, sortStats } = this.state;

		/** Set loading stats */
		this.setState({isLoading:true},()=>{
			/** Set api params */
			let skip 	=	(currentPage-1)*rowsPerPage;
			let apiReq 	=	{ model: 'settings', method: 'list', length: rowsPerPage, start: skip};
			apiReq = {...apiReq, ...{sort_by: sortStats.key ? sortStats.key : "title", sort_direction: sortStats.dir ? sortStats.dir : "asc"} };
			if(searchStats && Object.keys(searchStats).length){
				Object.keys(searchStats).forEach(key=>{
					if(searchStats[key]){
						apiReq = {...apiReq, ...{[key]: searchStats[key].value ? searchStats[key].value :""} }
					}
				});
			}

			/** Set search stats in cookies  */
			setOrGetCookiesValue('searchStatsReportSettingList', true, searchStats);

			/** get data table data list */
			callBackendAPI([apiReq]).then(response=>{
				if(response.success && response.data[0].status === STATUS_SUCCESS){
					this.setState({
						reportList 	:	response.data[0].result,
						totalRecords: 	response.data[0].recordsTotal,
						totalPages	: 	Math.ceil(response.data[0].recordsTotal/rowsPerPage),
						isLoading	: 	false,
						fileUrl		:	response.data[0].file_url,
						noImgUrl	:	response.data[0].no_img_url,
						reportDropdown	:	response.data[0].report_list,
					});
				}
			}).catch(err => console.log(err));
		});
	};// end getTableList()


	/** Function to handle delete button click */
	handleDelete = (reportSettingId) => {
		const confirmation = customConfirmBox(translate('settings.you_want_to_delete_report_setting'));
		confirmation.then((confirmed) => {
			if (confirmed) {
				this.closeModal();
				callBackendAPI([{ model: 'settings', method: 'deleteOne', report_setting_id: reportSettingId  }]).then((response) => {
					if (response.success) {
						this.handleChangePage();
						reFormatSuccess(response.data[0].message);
					}
				}).catch(err => console.log(err));
			}
		});
	}

	openDetailModal = (userDetails) => {
		this.setState({ showDetailModal: true, profileDetails: userDetails },()=>{
			window.openModal('reportSettingDetailModal');
		});
	};

	// Close the modal
	closeModal = () => {
		window.closeModal('reportSettingDetailModal');
		this.setState({showModel: false, showDetailModal: false, profileDetails: null });
	};

	render() {
        const {reportList, showModel, searchStats, currentPage, rowsPerPage, totalPages, isLoading , editId, sortStats, showDetailModal, profileDetails} = this.state;

		return <>
			<div className="dashboard-content">
				<div className="dashboard-heading d-flex justify-content-between align-items-center section-head">
					<div className="left-heading d-flex justify-content-between align-items-center">
						<h1>{translate("settings.report_settings")}</h1>
					</div>
					<div className="right-button">
						<Link to="#" className="btn btn-fill btn-filter " data-bs-toggle="collapse" data-bs-target="#subContractorcollapseOne" aria-expanded="true" aria-controls="subContractorcollapseOne">
							<img src={filterImg} alt="Img" width={29} height={26} />
						</Link>
						<div className="dropdown filter-new-btn">
							<button className="btn btn-fill dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
								<img src={plusIcon} alt="Img" width={20} height={20} />
								New
							</button>
							{([ADMIN, DISPATCHER].indexOf(Auth.getPermission()) !== -1) &&
								<>
								<ul className="dropdown-menu">
									<li>
										<Link to="#" className="dropdown-item" onClick={() => this.toggleModal(true)}>{translate("subcontractor.add_subcontractor")}</Link>
									</li>
								</ul>
								</>
							}
						</div>
					</div>
				</div>
				<div className="filter-box">
					<div className="accordion" id="accordionExample">
						<div className="accordion-item">
							<div id="subContractorcollapseOne" className="accordion-collapse collapse show" data-bs-parent="#accordionExample">
								<div className="accordion-body">
									<div className="filter-box-header d-flex justify-content-between mb-3">
										<h3 className="mb-0">{translate("employee.filter")}</h3>
										<Link to="#" className="btn-filter-close" data-bs-toggle="collapse" data-bs-target="#subContractorcollapseOne" aria-expanded="true" aria-controls="subContractorcollapseOne">
											<img src={closeIcon} alt="Img" width={20} height={20} />
										</Link>
									</div>
									<form className="adduser-form" onSubmit={(e)=>{ this.handleChangePage(1,e)}}>
										<div className="row">
											<div className="col-lg-4">
												<div className="form-group mb-3">
													<label htmlFor="title" className="form-label mb-1">{translate("settings.title")}</label>
													<input type="text" className="form-control input-with-label" id="title" placeholder={translate("settings.title")} value={searchStats["title"] ? searchStats["title"]["value"] : ""} onChange={(event) => this.handleFormInputs(event, "title")}/>
												</div>
											</div>
											<div className="col-md-12 d-flex">
												<button className="btn btn-fill me-3 width-height-initial" type="submit" onClick={(e)=>{ this.handleChangePage(1,e) }} >{translate("system.submit")}</button>
												<button className="btn btn-outline" onClick={() => this.resetSearchStats()}>{translate("system.reset")}</button>
											</div>
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="row mb-3">
					<div className="col-7">
						<div className="form-group d-flex align-items-center mb-3 table-count">
							{translate("system.show")}
							<Select
								className="custom-react-select mx-1"
								options={PAGING_LENGTH_MENU.map(key=>{return {value: key, label: key }})}
								value={{label:rowsPerPage, value:rowsPerPage}}
								closeMenuOnSelect={true}
								onChange={this.handlePageLengthChange}
							/>
							{translate("system.entries")}
						</div>
					</div>
				</div>

                <div className="users-list">
					<div className="table-responsive theme-table">
						<Table bordered responsive>
							<thead>
								<tr>
									<th width='15%'>
										{translate('settings.title')}
									</th>
									<th width='15%'>
										{translate('settings.time')}
									</th>
									<th width='15%'>
										{translate('settings.time_period')}
									</th>
									<th width='15%'>
										{translate('settings.start_date')}
									</th>
								</tr>
							</thead>
							<tbody>
								{isLoading ?
									<tr>
										<td colSpan="7" className='text-center'>
											<img src={loaderGif} alt="loading-icon" />
										</td>
									</tr>
								: reportList && reportList.length ?
									reportList.map((row, rowIndex) => (
										<tr key={"report-list" + rowIndex}>
											<td>
												<Link
													to="#"
													className="user-type-link view-modal-details"
													onClick={(e) => {
														e.preventDefault();
														this.openDetailModal(row);
													}}
												>
													{row.title ? row.title : ""}
												</Link>
											</td>
											<td>
												<span className="user-type">
													{row.time ? row.time : ""}
												</span>
											</td>
											<td>
												<span className="user-type">
													{row.time_period ? row.time_period : ""}
												</span>
											</td>
											<td>
												<span className="user-type">
													{row.start_date ? formatDate(row.start_date , DATERANGE_DATE_TIME_FORMAT) : ""}
												</span>
											</td>
										</tr>
									))
								:
									<tr>
										<td colSpan="7" className='text-center'>
											<div className="text-center">{translate("employee.no_data_available_in_table")}</div>
										</td>
									</tr>
								}
							</tbody>
						</Table>
					</div>
					<EllipsisPagination currentPage={currentPage} totalPages={totalPages} onChange={this.handleChangePage} />
				</div>
			</div>
		  	{showModel ?
				<AddForm editId={editId} getTableList={this.getTableList}  toggleModal={this.toggleModal} reportDropdown={this.state.reportDropdown} handleDelete={this.handleDelete} />
			: null}
			 {/*Detail Modal component */}
			 {showDetailModal ?
				<ReportSettingDetailModal profileDetails={profileDetails} toggleModal={this.toggleModal} reportDropdown={this.state.reportDropdown} />
			: null}
		</>
	}
}
export default ReportSettingList;