import React, { Component } from "react";
import { translate, formatDate } from './../../helpers/intl_helpers';
import { upperCaseOfName  } from '../../helpers/common';
import { AGREEMENT_TYPE_DROPDOWN, ADMIN, DISPATCHER, DATERANGE_DATE_TIME_FORMAT, WEEK_DAYS, WEEKLY, MONTHLY, YEARLY, EXPORT_TYPE_DROPDOWN } from '../../config/global_constants';
import { NavLink as Link} from "react-router-dom";

import Auth from './../../helpers/Auth';

import callIcon 	from '../../assets/images/call-icon.svg';
import mailIcon 	from '../../assets/images/mail-icon.svg';
import editIcon 	from '../../assets/images/edit-icon.svg';
import deleteIcon 	from '../../assets/images/delete-icon.svg';

export default class ReportSettingDetailModal extends Component {
	constructor(props){
        super(props)
        this.state = {};
    }

	/**
	* For hide model
	*
	* @return null
	*/
	closeModal(){
        window.closeModal('reportSettingDetailModal');
	}// end closeModal()

	/** Function to handle image lightbox */
	handleImageClick = (imageUrl) => {
		this.setState({
			lightBoxShow	: true,
			selectedImage	: imageUrl
		});
	}

	render() {
		const { profileDetails, toggleModal, reportDropdown } = this.props;

		console.log("reportDropdown",reportDropdown)

		return (
			<>
			<div className="modal fade crew-modal" id="reportSettingDetailModal" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
				<div className="modal-dialog modal-xl">
					<div className="modal-content">
						<button type="button" className="btn-close" onClick={()=> {this.closeModal()} }>X Close</button>

						<div className="modal-body">
							{/* <h3>{profileDetails.title ? profileDetails.title : translate('calendar.details')}</h3> */}
							<div className="list-group list-group-flush">
								<ul className="details-listing">
									<li>
										<div className="inner-wrap">
											<p>{translate('settings.title')}</p>
											<span>{profileDetails.title ? profileDetails.title : "N/A"}</span>
										</div>
									</li>
									<li>
										<div className="inner-wrap">
											<p>{translate('settings.time')}</p>
											<span>{profileDetails.time ? profileDetails.time : "N/A"}</span>
										</div>
									</li>
									<li>
										<div className="inner-wrap">
											<p>{translate('settings.time_period')}</p>
											<span>{profileDetails.time_period ? profileDetails.time_period : "N/A"}</span>
										</div>
									</li>
									{(profileDetails && profileDetails.time_period && profileDetails.time_period === WEEKLY) && (
										<li>
											<div className="inner-wrap">
												<p>{translate('settings.week_day')}</p>
												<span>
													{WEEK_DAYS.find((day) => day.value === parseInt(profileDetails.week_day))?.label || "N/A"}
												</span>
											</div>
										</li>
									)}

									{(profileDetails && profileDetails.time_period && (profileDetails.time_period === MONTHLY || profileDetails.time_period === YEARLY)) && (
										<li>
											<div className="inner-wrap">
												<p>{translate('settings.repeat_date')}</p>
												<span>
													{profileDetails.repeat_date
														? formatDate(profileDetails.repeat_date, DATERANGE_DATE_TIME_FORMAT)
														: "N/A"}
												</span>
											</div>
										</li>
									)}

									<li>
										<div className="inner-wrap">
											<p>{translate('settings.starting')}</p>
											<span>{profileDetails.start_date ? formatDate(profileDetails.start_date , DATERANGE_DATE_TIME_FORMAT) : ""}</span>
										</div>
									</li>
									<li>
										<div className="inner-wrap">
											<p>{translate('settings.employees')}</p>
											<span>
												{profileDetails.employee_emails ? profileDetails.employee_emails : "N/A"}
											</span>
										</div>
									</li>
									<li>
										<div className="inner-wrap">
											<p>{translate('settings.manual_emails')}</p>
											<span>{profileDetails.custom_emails ? profileDetails.custom_emails : "N/A"}</span>
										</div>
									</li>
									<li>
										<div className="inner-wrap">
											<p>{translate('settings.report')}</p>
											<span>
											{profileDetails.reports?.length > 0
												? profileDetails.reports
													.map((report) => {
														const match = reportDropdown.find((item) => item.value === String(report));
														return match ? match.label : null;
													})
													.filter(Boolean)
													.join(", ")
												: "N/A"}
											</span>
										</div>
									</li>
									<li>
										<div className="inner-wrap">
											<p>{translate('settings.export_type')}</p>
											<span>{EXPORT_TYPE_DROPDOWN.find((day) => day.value === profileDetails.export_type)?.label || "N/A"}</span>
										</div>
									</li>
								</ul>
							</div>
							{([ADMIN, DISPATCHER].indexOf(Auth.getPermission()) !== -1) &&
								<div className="detail-modal-editbtns">
									<Link to="#" onClick={() => toggleModal(true, profileDetails._id)}>
										<img src={editIcon} alt="img" width={21} height={21} />
										Edit
									</Link>
								</div>
							}
						</div>
					</div>
				</div>
			</div>
			</>
		)
	}
}
