import 'antd/lib/grid/style/index.css';
import '../../../assets/calendar-core-css/style.css';
import '../../../assets/css/calendar.css';

import React, {Component} from 'react';
import DatePicker from "react-datepicker";
import moment from 'moment';
import "react-datepicker/dist/react-datepicker.css";
import 'moment/locale/en-gb';
import { NavLink as Link} 	from "react-router-dom";
import Select from 'react-select';
import {AsyncPaginate} from 'react-select-async-paginate';

import Col from 'antd/lib/col';
import Row from 'antd/lib/row';
import Scheduler, {SchedulerData, ViewTypes, DnDSource} from './../index';
import {DnDTypes} from './DnDTypes';
import TaskItem from './TaskItem';
import AssetItem from './AssetItem';
import AssetList from './AssetList';
import MemberItem from './MemberItem';
import MemberList from './MemberList';
import ContractorItem from './ContractorItem';
import ResourceItem from './ResourceItem';

import withDragDropContext from './withDnDContext';
import TaskModal from './TaskModal';
import NotesModal from './NotesModal';
import AssignModal from './assignModal';
import PaidUnpaidAssignModal from './paidUnpaidAssignModal';
import EventItemDetailsModal from './../EventItemModal'
import MoveShopModal from './MoveShopModal'
import AddPtoYardJob from './AddPtoYardJob'
import LeftMenu from './../../../elements/LeftMenu';
import AddSubContractorJob from './AddSubContractorJob';
import AssetDetailModal from './AssetDetailModal';
import { UserDetails }  from '../../../UserDetails';
import TooltipElement from '../TooltipElement';
import DeleteModal from './../DeleteModal'

import closeImg         from '../../../assets/images/close_icon.svg';
import arrowRight       from '../../../assets/images/arrow_right.svg';
import logo             from '../../../assets/images/logo.png';
import logoWhiteImg 	from '../../../assets/images/logo-white.png';
import calendar         from '../../../assets/images/calendar.svg';
import filterImgBlue    from '../../../assets/images/filter-icon-blue.svg';
import fileEdit         from '../../../assets/images/file-edit.svg';
import sunIcon          from '../../../assets/images/sun_icon.svg';
import moonIcon         from '../../../assets/images/moon_stars_icon.svg';
import reset            from '../../../assets/images/reset.svg';
import arrowDown        from '../../../assets/images/arrow_down_blue.svg';
import addIcon          from '../../../assets/images/add.svg';
import searchImg        from '../../../assets/images/search_icon.svg';
import editIcon         from '../../../assets/images/edit_mode_icon.svg';
import closeIcon        from '../../../assets/images/close_icon.svg';
import hamburgermenuIcon from '../../../assets/images/hamburgermenu.svg';
import kebabIcon        from '../../../assets/images/three_dot_icon.svg';

import JobLogsModal from './../JobLogsModal';

import { formatDate, translate } from '../../../helpers/intl_helpers';
import { CALENDAR_DATE_TIME_FORMAT, STATUS_SUCCESS, DEPARTMENT_DROPDOWN, SHIFT_DAY, ACTIVE, CALENDAR_YARD, CALENDAR_PTO, CALENDAR_SUB_CONTRACTOR, FIBER_DEPARTMENT, DATE_PICKER_DATE_FORMAT, PROJECT_MANAGER, ADMIN, DISPATCHER, FOREMAN, CALENDAR_START_DATE_TIME_FORMAT } from '../../../config/global_constants';

import { callBackendAPI, customConfirmBox, reFormatSuccess, logout, upperCaseOfName, handleFormInputs, CustomInput, convertTo12HourFormat, manageJobAction, setOrGetCookiesValue, reFormatErrors } from '../../../helpers/common';

let schedulerData = null;
class DragAndDrop extends Component{
    static contextType = UserDetails;
    constructor(props){
        super(props);
        this.assetsChildRef =  React.createRef();
        this.memberChildRef =  React.createRef();

        /** Get search stats from cookies  */
		let tmpCalDate      =   new Date();
		let tmpSearchVal    =   '';
		let tmpSearchStats  =   {'selectedOption' : {value: "employees", label: "Employee"}};
		let tmpVal =  setOrGetCookiesValue('searchStatsJobCalendarList');
		if(tmpVal && tmpVal.constructor === Object && Object.keys(tmpVal).length){
            tmpSearchStats  =   tmpVal;

            if(tmpVal.searchValue) tmpSearchVal = tmpVal.searchValue;
            if(tmpVal.calendarDefaultDate){
                if(new Date(tmpVal.calendarDefaultDate) >= new Date()){
                    tmpCalDate = new Date(tmpVal.calendarDefaultDate);
                }
            }
        }

        let tmpDarkVal =  setOrGetCookiesValue('searchStatsDarkMode');

        this.state = {
            is_loading      : true,
            filterStats     : tmpSearchStats,
            viewModel       : null,
            taskDndSource   : null,
            resourceDndSource: null,
            assetDndSource  : null,
            shopDndSource   : null,
            resourceList    : [],
            eventList       : [],
            crewMemberList  : [],
            assetList       : [],
            shopList        : [],
            dateObj         : {},
            isAssignModal   : false,
            jobOptions      : "",
            jobId           : "",
            foremanId       : "",
            type            : "",
            memberAsseId    : "",
            isMonthView     : false,
            isMobileScreen  : false,
            isMobileDate    : false,
            userDetail	    : {},
			imageUrl	    : "",
			noImageUrl 	    : "",
            eventDetailsId  :  "",
            showEventDetailsModal  :  false,
            is_foreman_disable  :  false,
            isCollapsView   :  false,
            isWeekend       :  false,
            isHidequipment  :  false,
            jobDetailsForEditOrCopy  :  {},
            calendarDefaultDate : tmpCalDate,
            assetDetails    : "",
            searchValue     : tmpSearchVal,
            locationList    : [],
            isMoveToShopModal: false,
            movedAssetId: "",
            eventLogId: "",
            visibleJobBtns: "",
            scheduleList: [],
            isDarkMode: (tmpDarkVal && tmpDarkVal.isDarkMode === true) ? true : false,
            showDeleteModal: false,
            deletedJobGroupId: "",

            proManagerList      : [],
            foremanList         : [],
            jobNumberList       : [],
            subcontractorList   : [],
            filterAssetList     : []
        };

        this.handleChange               =   this.handleChange.bind(this);
        this.handleFormInputs 			=   this.handleFormInputs.bind(this);
        this.getCalendarTaskList        =   this.getCalendarTaskList.bind(this);
        this.getSearchDropDownList      =   this.getSearchDropDownList.bind(this);
        this.getCalendarResourceList    =   this.getCalendarResourceList.bind(this);
        this.handleWindowSizeChange     =   this.handleWindowSizeChange.bind(this);
        this.getUserDetails             =   this.getUserDetails.bind(this);
        this.toggleDarkMode             =   this.toggleDarkMode.bind(this);
        this.Logout                     =   this.Logout.bind(this);
		window.scrollTo(0, 0);
    }

    handleWindowSizeChange = ()=>{
		this.setState({isMobileScreen: window.innerWidth < 1200 ? true :false});
	}

    handleChange = (fieldName) => (fieldVal) => {
        let allFields = this.state.filterStats;
        allFields[fieldName]= fieldVal;

        if(fieldName === "location") allFields['department'] = null;
        if(fieldName  === "department" && fieldVal && fieldVal.value !== FIBER_DEPARTMENT) allFields.fiber_task= null;
        if(fieldName === "job_number") 	allFields['work_order'] = null;

        this.setState({
            filterStats: allFields,
        });
    };

    getFilterDropdownLists = () =>{
        /** Api Params Request */
        let apiParams = [
            { model: 'calendar', method: 'getUsersDropdownList', is_project_manager: true },
            { model: 'calendar', method: 'getUsersDropdownList', is_foreman:true },
            { model: 'calendar', method: 'cannonJobList' },
            { model: 'calendar', method: 'getUsersDropdownList', is_subcontractor: true },
            { model: 'calendar', method: 'getAssetDropdownList'}
        ];

        /** Call API */
        callBackendAPI(apiParams).then(response => {
			if(response.success && response.data[0].status === STATUS_SUCCESS && response.data[0].result) {

                /** Set update stats */
                let updateStats = {
					proManagerList      : response.data[0].result,
					foremanList         : response.data[1].result,
					jobNumberList       : response.data[2].result,
					subcontractorList   : response.data[3].result,
					filterAssetList     : response.data[4].result
				};

				this.setState(updateStats,()=>{
                    window.hideLoader();
                });
			}
		}).catch(err => console.log(err));
    }

    /**
     * Fetches user details from the backend and updates the component's state accordingly.
     * The function performs the following steps:
     * 1. Calls the backend API to retrieve user details.
     * 2. Checks if the response is successful and contains valid user details.
     * 3. Updates the filter stats with the user's permission level and full name.
     * 4. Updates the department dropdown list based on the user's departments.
     * 5. Updates the component state with user details, image URLs, and filter stats.
     * 6. Logs out the user if the response is not successful.
    */
    getUserDetails = () =>{
        callBackendAPI([{ model: 'my_account', method: 'getUserDetails'},{ model: 'home', method: 'masterList', type: ["location"] },{ model: 'home', method: 'scheduleList'}]).then(response => {
            if(response.success && response.data[0].status === STATUS_SUCCESS && response.data[0].result) {
                let userDetails = response.data[0].result;
                let locationList = response.data[1].result.location;
                let scheduleList = response.data[2].result;

                let deptList = userDetails.department       ? userDetails.department : [];
                let locationDetails = userDetails.location  ? userDetails.location   : [];

                let allFields = this.state.filterStats;
                if(userDetails.permission_level !== PROJECT_MANAGER){
                    allFields[userDetails.permission_level]= {label:userDetails.full_name, value: userDetails._id};
                }

                let selectedDept = DEPARTMENT_DROPDOWN.filter(department => deptList.includes(department.value))
                let selectedLoc = locationList.filter(location => locationDetails.includes(location.value))
                if(selectedLoc.length) allFields['location'] = selectedLoc;
                if(selectedDept.length) allFields['department'] = selectedDept;

				this.setState({
                    is_loading  : false,
					userDetail	: response.data[0].result,
					imageUrl 	: response.data[0].file_url,
					noImageUrl 	: response.data[0].no_img_url,
                    filterStats : allFields,
                    locationList : locationList,
                    scheduleList : scheduleList,
                    searchValue : (userDetails.full_name) ? userDetails.full_name : ""
				},()=>{
                    this.getCalendarResourceList(false,true);
                    this.interval = setInterval(() => {
                        this.getCalendarResourceList(true);
                    }, 90000);
                });
			}else{
                this.Logout();
            }
		}).catch(err => console.log(err));
    }// end getUserDetails()

    /**
	* For update state fields values
	*
	* @param e  	as current field object
	* @param field  as current field name
	*
	* @return null
	*/
	handleFormInputs(e, field) {
		let response = handleFormInputs(e, field, this.state.filterStats);
		this.setState({ response });
	}// end handleFormInputs()

    /**
	* For get search drop down listing
	*/
    async getSearchDropDownList(inputValue, loadedOptions, { page, type }) {
        try{
            /** Set API request */
            let apiReq;
            if(type === "crewMember" )      apiReq = { model: 'calendar', method: 'employeeList', emp_name: inputValue ? inputValue : '', is_crew_member: true};
            if(type === "location" )        apiReq = { model: 'home', method: 'masterData', type: "location", name: inputValue ? inputValue : ''};
            if(type === "fiber_task" )      apiReq = { model: 'home', method: 'masterData', type: "fiber_task", name: inputValue ? inputValue : ''};
            if(type === "workOrder" )       apiReq = { model: 'calendar', method: 'workOrderList', job_id: this.state.filterStats.job_number ? this.state.filterStats.job_number.value :"", work_order_number: inputValue ? inputValue : '', is_job_report: true}
            if(type === "skill_set" )       apiReq = { model: 'home', method: 'masterData', type: "skill_set", name: inputValue ? inputValue : ''};

            if(!apiReq) return {options: [],hasMore: false,additional: {page: page, type: type}};

            /** Add page number */
            apiReq.current_page = page;

            /** Call api for get drop down list */
            let response = await callBackendAPI([apiReq]);

			let resData 		= 	response && response.data && response.data[0] ? response.data[0] :{};
			let result 			=	resData.result ? resData.result :[];
			let recordsTotal 	=	resData.recordsTotal ? resData.recordsTotal:0;
			let completeArray 	=	result.concat(loadedOptions);
            if(type === "location" && completeArray[0] && completeArray[0].value){
                result 	        =   [...result];
                completeArray 	=   [...completeArray];
                recordsTotal   += 1;
            }

			return {
				options: result,
				hasMore: completeArray.length  === recordsTotal ? false :true,
				additional: {
					page: page + 1,
                    type: type
				},
			}
		} catch (error) {
			console.error('Error loading options:', error);
			return {
				options: [],
				hasMore: false,
				additional: {
					page: page,
                    type: type
				},
			};
		}
    }// end getSearchDropDownList()

	/**
	* For user logout
	*
	* @param null
	*
	* @return void
	*/
	Logout(){
		logout()
	}// end Logout()

    /** Set calendar view mode */
    componentDidMount(){
        window.showLoader();
        this.getUserDetails();
        this.getFilterDropdownLists();
        this.handleWindowSizeChange();
		window.addEventListener('resize', this.handleWindowSizeChange);
        window.addClassForMonthView('body','calendar-view-page');

        let savedDarkMode =  setOrGetCookiesValue('searchStatsDarkMode')
        let mockEvent = {
            target: {
                checked: (savedDarkMode && savedDarkMode.isDarkMode === true) ? true : false
            }
        };

        // Call the toggleDarkMode function with the mock event
        this.toggleDarkMode(mockEvent);

        let tmpViewArr = [
            {viewName: 'Week', tooltipTitle: 'Week View', viewType: ViewTypes.Week, showAgenda: false, isEventPerspective: false},
            {viewName: 'Month', tooltipTitle: 'Month View', viewType: ViewTypes.Month, showAgenda: false, isEventPerspective: false}
        ];

        schedulerData = new SchedulerData(formatDate(this.state.calendarDefaultDate, "YYYY-MM-DD"), ViewTypes.Week, false, false, {
            schedulerWidth      : '80%',
            schedulerMaxHeight  : 300,
            weekCellWidth       : "17.6%",
            monthCellWidth      : 140,
            views               : tmpViewArr
        });

        moment.locale('en-gb');
        schedulerData.localeMoment.locale('en-gb');

        schedulerData.setResources(this.state.resourceList);
        schedulerData.setEvents([]);
        schedulerData.setAssets([]);
        schedulerData.setShops([]);
        schedulerData.setMembers([]);
        this.setState ({
            viewModel: schedulerData,
            taskDndSource: new DnDSource((props) => {return props.task;}, TaskItem, DnDTypes.TASK),
            resourceDndSource: new DnDSource((props) => {return props.resource;}, ResourceItem, DnDTypes.RESOURCE),
            assetDndSource: new DnDSource((props) => {return props.task;}, AssetItem, DnDTypes.ASSET),
            memberDndSource: new DnDSource((props) => {return props.task;}, MemberItem, DnDTypes.MEMBER),
            shopDndSource: new DnDSource((props) => {return props.task;}, ContractorItem, DnDTypes.CONTRACTOR),
            visible: false,
            eventData: "",
            isOpen: false,
            isYardOrPtoOpen: false,
            isSubcontractorOpen: false,
            isFilterVisible: false,
            isSideMenuShow : false,
            isFilterModalOpen : false,
            startWeekDate : schedulerData.startDate,
            endWeekDate   : schedulerData.endDate,
            isAssginPaidOrUnpaid:  false
        });
    }

    /** Manage stats when component unmount */
    componentWillUnmount() {
        window.removeEventListener('resize', this.handleWindowSizeChange);
        clearInterval(this.interval);
    }

    handleHeaderFilter = (values) => {
        const { filterStats } = this.state;
        const updatedFilterStats = {
            ...filterStats,
            searchValue: values
        };
        // Update the state with the new filterStats
        this.setState({
            filterStats: updatedFilterStats
        },() => {
            this.getCalendarResourceList(); // Callback after state is updated
        });
    }

    /**
     * Fetches the list of resources for the calendar based on the specified filters and dates.
     *
     * @param {boolean} isRefresh - Indicates if the refresh is triggered by user action.
     * @param {boolean} isSetInitialDate - Whether to set the initial date after fetching resources.
    */
    getCalendarResourceList(isRefresh=false,isSetInitialDate=false){
        if(!isRefresh)
            window.showLoader();

        const {startWeekDate, endWeekDate, isMonthView, filterStats,isMobileScreen,searchValue} = this.state;

        /** Set api request params */
        let apiReq = { model: 'calendar', method: 'resourceList', month_view: isMonthView, from_date: formatDate(startWeekDate,CALENDAR_DATE_TIME_FORMAT), to_date: formatDate(endWeekDate,CALENDAR_DATE_TIME_FORMAT), job_filters: {}};

        let locArr     = [];
        let deptArr    = [];
        let foremanArr = [];
        let skillSetArr = [];
        let empArr      = [];
        let equipArr    = [];
        let subArr      = [];
        let projectManagerArr = [];
        if(filterStats.location && filterStats.location.length)     locArr      = filterStats.location.map(element => element.value);
        if(filterStats.department && filterStats.department.length) deptArr     = filterStats.department.map(element => element.value);
        if(filterStats.foreman && filterStats.foreman.length)       foremanArr  = filterStats.foreman.map(element => element.value);
        if(filterStats.skill_set && filterStats.skill_set.length)   skillSetArr = filterStats.skill_set.map(element => element.value);

        if(filterStats.employees && filterStats.employees.length)   empArr      =  filterStats.employees.map(element => element.value);
        if(filterStats.equipment && filterStats.equipment.length)   equipArr    =  filterStats.equipment.map(element => element.value);
        if(filterStats.subcontractor_id && filterStats.subcontractor_id.length)   subArr      =  filterStats.subcontractor_id.map(element => element.value);
        if(filterStats.project_manager && filterStats.project_manager.length)   projectManagerArr=  filterStats.project_manager.map(element => element.value);

        /** Add filters */
        if(filterStats.location && filterStats.location.length)      apiReq.location        =  locArr;
        if(filterStats.department && filterStats.department.length)  apiReq.department      =  deptArr;
        if(filterStats.foreman && filterStats.foreman.length)        apiReq.foreman_id      =  foremanArr;
        if(filterStats.skill_set && filterStats.skill_set.length)    apiReq.skill_set_ids   =  skillSetArr;

        if(filterStats.employees && filterStats.employees.length)    apiReq.employee_id                     =  empArr;
        if(filterStats.equipment && filterStats.equipment.length)    apiReq.asset_id                        =  equipArr;
        if(filterStats.subcontractor_id && filterStats.subcontractor_id.length) apiReq.subcontractor_id     =  subArr;
        if(filterStats.project_manager && filterStats.project_manager.length)   apiReq.project_manager_id   =  projectManagerArr;
        if(filterStats.job_number && filterStats.job_number.value)  apiReq.job_number_id                    =  filterStats.job_number.value;
        if(filterStats.work_order && filterStats.work_order.value)  apiReq.work_order_id                    =  filterStats.work_order.value;
        apiReq.searchValue                      =  isMobileScreen ? searchValue : filterStats.searchValue;

        callBackendAPI([apiReq]).then(response => {
            if (response.success && response.data && response.data[0] && response.data[0].result){
                let resourceList = response.data[0].result;
                schedulerData.setResources(resourceList);
                this.setState ({
                    resourceList        :   resourceList,
                    resourceDndSource   :   new DnDSource((props) => {return props.resource;}, ResourceItem, DnDTypes.RESOURCE),
                },()=>{
                    this.getCalendarTaskList(isSetInitialDate);
                });
             }
        }).catch(err => console.log(err));
    } // end getCalendarResourceList()

    /**
     * Fetches the list of calendar tasks based on the specified filters and dates.
     *
     * @param {boolean} isSetInitialDate - Whether to set the initial date after fetching.
    */
    getCalendarTaskList(isSetInitialDate=false){
        const {filterStats, startWeekDate, endWeekDate, isHidequipment, isMobileScreen, isMobileDate, searchValue, calendarDefaultDate} = this.state;

        let startWeek   =   isMobileScreen ? new Date(calendarDefaultDate) :startWeekDate;
        let endWeek     =   isMobileScreen ? new Date(calendarDefaultDate) :endWeekDate;

        if(isMobileDate){
            startWeek   =   startWeekDate;
            endWeek     =   endWeekDate;
        }

        /** Set api request params */
        let apiReq = {model: 'calendar', method: 'calendarTaskEventList',month_view: this.state.isMonthView, from_date: formatDate(startWeek,CALENDAR_DATE_TIME_FORMAT), to_date: formatDate(endWeek,CALENDAR_DATE_TIME_FORMAT), hide_equipment: isHidequipment, screen_type: isMobileScreen ? "is_mobile_screen" : "" };

        let locArr      = [];
        let deptArr     = [];
        let foremanArr  = [];
        let empArr      = [];
        let equipArr    = [];
        let subArr      = [];
        let projectManagerArr = [];

        if(filterStats.location && filterStats.location.length)     locArr      = filterStats.location.map(element => element.value);
        if(filterStats.department && filterStats.department.length) deptArr     = filterStats.department.map(element => element.value);
        if(filterStats.foreman && filterStats.foreman.length)       foremanArr  = filterStats.foreman.map(element => element.value);
        if(filterStats.employees && filterStats.employees.length)   empArr      =  filterStats.employees.map(element => element.value);
        if(filterStats.equipment && filterStats.equipment.length)   equipArr    =  filterStats.equipment.map(element => element.value);
        if(filterStats.subcontractor_id && filterStats.subcontractor_id.length)   subArr      =  filterStats.subcontractor_id.map(element => element.value);
        if(filterStats.project_manager && filterStats.project_manager.length)   projectManagerArr=  filterStats.project_manager.map(element => element.value);

        /** Add filters */
        if(filterStats.location && filterStats.location.length)      apiReq.location_mob                    =  locArr; // For mobile case
        if(filterStats.department && filterStats.department.length)  apiReq.department_mob                  =  deptArr; // For mobile case
        if(filterStats.fiber_task && filterStats.fiber_task.value)  apiReq.fiber_task                       =  filterStats.fiber_task.value;
        if(filterStats.foreman && filterStats.foreman.length)        apiReq.foreman_mob                     =  foremanArr;
        if(filterStats.employees && filterStats.employees.length)    apiReq.employee_id                     =  empArr;
        if(filterStats.equipment && filterStats.equipment.length)    apiReq.asset_id                        =  equipArr;
        if(filterStats.job_number && filterStats.job_number.value)  apiReq.job_number_id                    =  filterStats.job_number.value;
        if(filterStats.work_order && filterStats.work_order.value)  apiReq.work_order_id                    =  filterStats.work_order.value;
        if(filterStats.project_manager && filterStats.project_manager.length)   apiReq.project_manager_id   =  projectManagerArr;
        if(filterStats.subcontractor_id && filterStats.subcontractor_id.length) apiReq.subcontractor_id     =  subArr;
        if(filterStats.state && filterStats.state.value)            apiReq.state                            =  filterStats.state.value;
        if(filterStats.city && filterStats.city.value)              apiReq.city                             =  filterStats.city.value;
        if(filterStats.zip && filterStats.zip.value)                apiReq.zip                              =  filterStats.zip.value;
        apiReq.searchValue                      =  isMobileScreen ? searchValue : filterStats.searchValue;

        /** Set search stats in cookies  */
        setOrGetCookiesValue('searchStatsJobCalendarList', true, {...filterStats, ...{searchValue: apiReq.searchValue }, ...{calendarDefaultDate: calendarDefaultDate}});

        callBackendAPI([apiReq]).then(response => {
            if (response.success && response.data && response.data[0] && response.data[0].result){
                let taskList = response.data[0].result;

                schedulerData.setEvents(taskList);
                this.setState ({
                    eventList       : taskList,
                    taskDndSource   : new DnDSource((props) => {return props.task;}, TaskItem, DnDTypes.TASK),
                },()=>{
                    window.hideLoader();
                    if(isSetInitialDate && !isMobileScreen){
                        this.onSelectDate(schedulerData,formatDate(new Date(calendarDefaultDate),CALENDAR_START_DATE_TIME_FORMAT),true);
                    }
                });
             }
        }).catch(err => console.log(err));
    }// end getCalendarTaskList()

    handleSideMenuClick = () => {
        this.setState(prevState => ({
            isSideMenuShow: !prevState.isSideMenuShow
        }));
    }

    nonAgendaCellHeaderTemplateResolver = (schedulerData, item, formattedDateItems, style) => {
        const {scheduleList} = this.state;
        let datetime = schedulerData.localeMoment(item.time);
        let isCurrentDate = true;
        let eventName = "";
        let eventDesc = "";

        /**Set holiday event on header with date */
        if(scheduleList && scheduleList.length > 0){
            scheduleList.forEach(val=>{
                if(formatDate(val.date,CALENDAR_START_DATE_TIME_FORMAT) === item.time){
                    eventName = val.title ? val.title :"";
                    eventDesc = val.description ? '<br/>'+val.description :"";
                    item['is_holiday'] = true;
                }
            });
        }

        if (this.state.calendarDefaultDate) {
            let selectedDateTime = schedulerData.localeMoment(this.state.calendarDefaultDate);
            isCurrentDate = datetime.isSame(selectedDateTime, 'day');
        }
        if (isCurrentDate) {
            style.background = 'transparent linear-gradient(180deg, #D40200 0%, #FF3A39 100%) 0% 0% no-repeat padding-box';
            style.color = 'white';
        }

        return (
            <th key={item.time} className={'header3-text'} style={style}>
                {formattedDateItems.map((formattedItem, index) => (
                    <Link to="#" onClick={() => this.onSelectDate(schedulerData,item.time,true)} key={index}>
                        <div key={index} dangerouslySetInnerHTML={{ __html: formattedItem.replace(/[0-9]/g, '<b>$&</b>') }} />
                        {eventName ?
                            <TooltipElement title={eventName+eventDesc} placement='bottom' >
                                <span>{eventName}</span>
                            </TooltipElement>
                        :null}
                    </Link>
                ))}
            </th>
        );
    }

    /**
     * Updates the event calendar based on the provided type.
     *
     * @param {any} value - The value to be set in the scheduler (e.g., shops, assets).
     * @param {string} type - The type of update (e.g., "shop", "calendar", "complete_calendar", or others).
    */
    updateEventCalendar = (value, type) =>{
        if(type === "shop")   {
            schedulerData.setShops(value);
            this.setState ({
                shopDndSource: new DnDSource((props) => {return props.task;}, ContractorItem, DnDTypes.CONTRACTOR)
            });
        }else if(type === "calendar"){
            this.getCalendarTaskList();
        }else if(type === "complete_calendar"){
            this.getCalendarResourceList();
        }else{
            schedulerData.setAssets(value);
            this.setState ({
                assetDndSource: new DnDSource((props) => {return props.task;}, AssetItem, DnDTypes.ASSET),
            });
        }
    }// end updateEventCalendar()

    /**
    * Updates the calendar with new crew member data and reinitializes the drag-and-drop source.
    *
    * @param {Array} value - An array of new crew member data to be set in the scheduler.
    */
    updateCrewMemberCalendar = (value) =>{
        schedulerData.setMembers(value);
        this.setState ({
            memberDndSource: new DnDSource((props) => {return props.task;}, MemberItem, DnDTypes.MEMBER),
        })
    };// end updateCrewMemberCalendar()

    /**
     * Handles the logic for moving an item to a different category (e.g., shop or asset).
     *
     * @param {Event} e - The event object, used to prevent default behavior if applicable.
     * @param {number} itemId - The ID of the item to be moved.
     * @param {string} type - The type of category to move the item to ("shop" or other).
     */
    moveItem = (e,itemId,type) => {
        if(e) e.preventDefault();

        let message = translate("calendar.you_want_to_move_this_item_to_shop");
        if(type === "shop"){
            message = translate("calendar.you_want_to_move_this_item_to_asset");
            let confirmation = customConfirmBox(translate(message));

            confirmation.then((confirmed) => {
              if (confirmed) {

                callBackendAPI([{model: 'calendar', method: 'moveToShop', asset_id: itemId}]).then(response => {
                    if (response.success && response.data[0].status === STATUS_SUCCESS) {

                        this.assetsChildRef.current.moveChildItem();
                        reFormatSuccess(response.data[0].message)
                    }
                }).catch(err => console.log(err));
              }
            });
        }else{
            this.setState({
                isMoveToShopModal: true,
                movedAssetId : itemId
            },()=>{
                window.openModal("moveShopModal");
            })
        }
    };// end moveItem()

    refreshAssetOnMoveToShope = () =>{
        this.assetsChildRef.current.moveChildItem();
    }

    /**
     * Updates the state with the provided asset details and opens the asset details modal.
     *
     * @param {Object} assetDetails - The details of the asset to be set in the state.
     */
    setAssetItem = (assetDetails) =>{
        this.setState({
            assetDetails: assetDetails
        },()=>{
            window.openModal('assetDetailsModal');
        })
    }// end setAssetItem()

    /**
     * Assigns a member or asset to a job via a backend API call.
     *
     * @param {string} jobId - The ID of the job.
     * @param {string} foremanId - The ID of the foreman.
     * @param {string} type - The type of assignment ('asset' or 'member').
     * @param {string} memberAssetId - The ID of the member or asset being assigned.
     * @param {string} empType - The type of employee (paid/unpaid).
    */
    assignMemberAssetForJob = (jobId,foremanId,type,memberAssetId,empType) =>{
        callBackendAPI([
            { model: 'calendar', method: 'assignMemberOrAssetOnJob', job_id: jobId, foreman_id: foremanId,  [(type === "asset") ? "asset_id": "crew_member_id" ]:  memberAssetId, "emp_type": empType },
        ]).then(response => {
            if (response.success) {
                window.closeModal('assignModal');
                this.setState({
                    isAssignModal : false
                });
                /**Call function for append side list employees/equipment/events */
                reFormatSuccess(response.data[0].message);
                this.updateEventCalendar(null,"calendar");
            }else{
                let tmpErrors =  reFormatErrors(response.data[0].message);
                let isForceAssign = (tmpErrors &&  tmpErrors != null && tmpErrors.force_resource_id ? tmpErrors.force_resource_id : "");
                if(isForceAssign){
                    window.closeModal('assignModal');
                    let message = isForceAssign;
                    let confirmation = customConfirmBox(message,"",false,true);
                    confirmation.then((confirmed) => {
                        if (confirmed) {
                            callBackendAPI([
                                { model: 'calendar', method: 'assignMemberOrAssetOnJob', job_id: jobId, foreman_id: foremanId,  [(type === "asset") ? "asset_id": "crew_member_id" ]:  memberAssetId, "emp_type": empType, force_assign: true },
                            ]).then(response => {
                                if (response.success) {
                                    window.closeModal('assignModal');
                                    this.setState({
                                        isAssignModal : false
                                    });
                                    /**Call function for append side list employees/equipment/events */
                                    reFormatSuccess(isForceAssign)
                                    this.updateEventCalendar(null,"calendar") ;
                                }
                            }).catch(err => console.log(err));
                        }else{
                            this.setState({
                                isAssignModal : false
                            });
                        }
                    });
                }
            }
        }).catch(err => console.log(err));
    }// end assignMemberAssetForJob()

    /**
     * Closes all open modals by resetting the relevant state values.
     *
     * @return {null}
    */
    handleCloseTaskModal = () =>{
        window.closeModal('eventLogsModal');
        this.setState({
            isOpen                  :   false,
            isYardOrPtoOpen         :   false,
            isSubcontractorOpen     :   false,
            isAssginPaidOrUnpaid    :   false,
            eventLogId              :   "",
            dateObj                 :   {},
            jobDetailsForEditOrCopy :   {},
            deletedJobGroupId: "",
            showDeleteModal: false,
        });
    }// end handleCloseTaskModal()

    /**
     * Opens the specified task modal.
     *
     * @param {string} modalId - The ID of the modal to be opened.
     * @return {null}
     */
	openTaskModal = (modalId) =>{
        const { isMobileScreen } = this.state;
        this.setState({
            isOpen: true,
            dateObj : {isMobileScreen: isMobileScreen}
        },()=>{
            window.openModal(modalId);
        })
	}// end openTaskModal()

    /**
     * Opens the appropriate job modal based on the slot type.
     *
     * @param {string} slotType - The type of slot (e.g., "subcontractor" or others).
     * @return {null}
    */
	openJobModal = (slotType,roleType = "") =>{
        const { isMobileScreen } = this.state;
        this.setState({
            isYardOrPtoOpen: (slotType !== "subcontractor" && roleType === "") ? true : false,
            isSubcontractorOpen: (slotType === "subcontractor") ? true : false,
            isAssginPaidOrUnpaid: (slotType === "pto" && roleType === FOREMAN) ? true : false,
            dateObj : {slot_type: slotType, isMobileScreen: isMobileScreen, role_type: roleType},
        },()=>{
            if(slotType === "subcontractor"){
                window.openModal('assSubContractorJob')
            }else{
                if(roleType === FOREMAN){
                    window.openModal('assignPaidUnpdaidModal')
                }else{
                    window.openModal('addPtoYardJob');
                }
            }
        })
	}// end openJobModal()

    /**
     * Refreshes the member and asset lists by calling their respective methods.
    */
    refreshMemberAssetList = () =>{
        this.assetsChildRef.current.handleAssetListByCalendarDate(true);
        this.memberChildRef.current.handleCrewListByCalendarDate(true);
    }// end refreshMemberAssetList()

    /**
     * Assigns an employee type for a PTO job and opens the appropriate modal.
     *
     * @param {string} empType - The type of employee being assigned (e.g., paid or unpaid).
    */
    assignEmployeeForPtoJob = (empType) => {
        window.closeModal('assignPaidUnpdaidModal');
        this.setState((prevState) => ({
            dateObj: {
                ...prevState.dateObj,
                emp_type: empType
            },
            isYardOrPtoOpen: true,
        }), () => {
            window.openModal('addPtoYardJob');
        });
    }// end assignEmployeeForPtoJob()

    /**
     * Handles changes to the input field.
     *
     * @param {Object} event - The event object from the input change.
    */
    handleInputChange = (event) => {
        this.setState({ searchValue: event.target.value });
    };// end handleInputChange()

    /**
     * Handles button click events for filtering.
     *
     * @param {string} type - The type of button clicked, determining the action.
    */
    handleButtonClick = (type) => {
        if(type==="clear"){
            this.setState({ searchValue: "" },()=>{
                this.handleHeaderFilter("");
            });
        }else{
            this.handleHeaderFilter(this.state.searchValue);
        }
    }; // end handleButtonClick()

    /**
     * Opens the appropriate job logs modal based on the job id
     *
     * @param {string} eventId - job id
     *
     * @return {null}
    */
	openJobLogModal = (eventId) =>{
        this.setState({eventLogId: eventId});
	}// end openJobLogModal()

    toggleJobBtns = (eventId) => {
        this.setState(prevState => ({
            visibleJobBtns: prevState.visibleJobBtns === eventId ? null : eventId
        }));
    };

    /**
	* Toggle dark mode based on checkbox
	*/
	toggleDarkMode(event) {
		const isChecked = event.target.checked;

        setOrGetCookiesValue('searchStatsDarkMode', true, { isDarkMode : isChecked});

		this.setState({ isDarkMode: isChecked });

		// Add or remove 'dark-mode' class based on checkbox state
		if (isChecked) {
			document.body.classList.add('dark-mode');
		} else {
			document.body.classList.remove('dark-mode');
		}
	}

    handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            this.handleButtonClick(); // Trigger search on Enter key press
        }
    };

    render(){
        const {viewModel, taskDndSource, resourceDndSource, assetDndSource, shopDndSource, memberDndSource, is_loading , dateObj, isOpen,isYardOrPtoOpen,isSubcontractorOpen, isAssignModal, jobOptions, jobId, foremanId, type, memberAsseId, filterStats, isMonthView, isMobileScreen, eventList, userDetail, imageUrl, noImageUrl, assetDetails, isAssginPaidOrUnpaid, is_foreman_disable, locationList, isMoveToShopModal, movedAssetId, proManagerList, foremanList, jobNumberList, subcontractorList, filterAssetList} = this.state;
        const permissionLevel = (userDetail && userDetail.permission_level) ? userDetail.permission_level : "";

        const isReadOnly = (permissionLevel && (permissionLevel === PROJECT_MANAGER || permissionLevel === ADMIN || permissionLevel === DISPATCHER)) ? false : true;
        if(!is_loading){
            if(isMonthView)
                window.addClassForMonthView('body','month-view');
            else
                window.removeClassForMonthView('body','month-view');

            if(isReadOnly) window.addClassForMonthView('body','month-view');
        }
        if(viewModel){
            viewModel.is_read_only = isReadOnly;
            viewModel.permission_level = permissionLevel;
        }

        let isAttachEditBtnShow = (permissionLevel && (permissionLevel === FOREMAN || permissionLevel === PROJECT_MANAGER || permissionLevel === DISPATCHER || permissionLevel === ADMIN)) ? true : false;

        let assetList       = is_loading ? null : <AssetList schedulerData={viewModel} moveItem={this.moveItem} setAssetItem={this.setAssetItem} ref={this.assetsChildRef} updateEventCalendar={this.updateEventCalendar} newEvent={this.newEvent} assetDndSource={assetDndSource} shopDndSource={shopDndSource} onSelectDate={this.onSelectDate} userDetail={userDetail} />
        let memberList      = is_loading ? null : <MemberList schedulerData={viewModel} updateCrewMemberCalendar={this.updateCrewMemberCalendar} newEvent={this.newEvent} memberDndSource={memberDndSource} onSelectDate={this.onSelectDate} ref={this.memberChildRef} setAssetItem={this.setAssetItem} userDetail={userDetail} />

        //register the external DnDSources
        let dndSources = [taskDndSource, resourceDndSource, assetDndSource, shopDndSource, memberDndSource];
        return (
            <div>
                <LeftMenu />
                <div>
                    <div className='calendar-header'>
                        <div className='calendar-container'>
                            <div>
                            <button type="button" className="menu-btn"><img src={hamburgermenuIcon} alt="Menu Button" id="menu-button" width="25" height="18"/></button>
                                <Link to='/dashboard' className='logo header-logo'>
                                    <img src={logo} alt='Logo' className="logo-basic" width={137} height={82}/>
                                    <img src={logoWhiteImg} className="logo-white" alt='logo-white' width={137} height={82}/>
                                </Link>

                            </div>
                            <div className='select-date'>
                                <div className='form-group'>
                                    <div className="form-floating">
                                        <span className='data-icon'><img src={calendar} alt='Images' width={25} height={25}/></span>
                                        <label>{translate("calendar.change_date")}</label>
                                        <DatePicker
                                            selected={this.state.calendarDefaultDate ? this.state.calendarDefaultDate : null}
                                            onChange={(date) => this.onSelectDate(schedulerData,date,false)}
                                            className="form-control"
                                            dateFormat={DATE_PICKER_DATE_FORMAT}
                                            placeholderText={translate('calendar.select_date')}
                                            dropdownMode="select"
                                            showMonthDropdown
                                            showYearDropdown
                                            customInput={<CustomInput />}
                                        />
                                    </div>
                                </div>
                            </div>
                            {isMobileScreen &&
                                <div className='calendar-search me-3'>
                                    <div className="search-filter-section mb-0 ms-0 ms-xl-2 ms-lg-2">
                                        <input
                                            type="text"
                                            className='form-control'
                                            placeholder={translate('calendar.search_by_name_equipment_job')}
                                            value={this.state.searchValue}
                                            onChange={this.handleInputChange}
                                            onKeyDown={this.handleKeyDown}
                                        />
                                        <button className="value-clear-btn" onClick={() => this.handleButtonClick("clear")}>
                                            <img src={closeIcon} alt="img" />
                                        </button>
                                        <button className="search-icon" onClick={() => this.handleButtonClick()}>
                                            <img src={searchImg} alt='Search' width={20} height={20} />
                                        </button>
                                    </div>
                                </div>
                            }
                            <div className='calendar-header-right d-flex align-items-center'>
                                <div className='header-button'>
                                    <ul className='d-flex align-items-center justify-content-center m-0 p-0'>
                                        <li className="dropdown add_icon_mobile">
                                            <Link className="dropdown-toggle" to="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                <TooltipElement title={translate("calendar.add_new_project")} placement='bottom' >
                                                    <img src={addIcon} alt='' width={27} height={27}/>
                                                </TooltipElement>
                                            </Link>
                                            <ul className="dropdown-menu">
                                                {(!isReadOnly) &&
                                                    <>
                                                        <li><Link to='#' onClick={()=>this.openTaskModal('myModal')} className="dropdown-item" href="#">{translate("calendar.new_project")}</Link></li>
                                                        <li><Link to='#' onClick={()=>this.openJobModal('yard')} className="dropdown-item" href="#">{translate("calendar.yard_task")}</Link></li>
                                                        <li><Link to='#' onClick={()=>this.openJobModal('subcontractor') } className="dropdown-item" href="#">{translate("calendar.subcontractor_job")}</Link></li>
                                                        <li><Link to='#' onClick={()=>this.openJobModal('pto')} className="dropdown-item" href="#">{translate("calendar.time_off_request")}</Link></li>
                                                    </>
                                                }
                                                {(permissionLevel === FOREMAN) &&
                                                    <li><Link to='#' onClick={()=>this.openJobModal('pto',FOREMAN)} className="dropdown-item" href="#">{translate("calendar.time_off_request")}</Link></li>
                                                }
                                                {(permissionLevel === ADMIN || permissionLevel === DISPATCHER) &&
                                                    <li className='d-block d-xl-none'><Link to='#' onClick={()=>window.openModal('notesModal')} className="dropdown-item" href="#">Add Notes</Link></li>
                                                }
                                            </ul>
                                        </li>

                                        <li>
                                            <Link to='#'  data-bs-toggle="collapse" data-bs-target="#collapseHeaderFilter" aria-expanded="false" aria-controls="collapseHeaderFilter">
                                                <img src={filterImgBlue} alt='' width={27} height={27}/>
                                            </Link>
                                        </li>
                                       {(permissionLevel === ADMIN || permissionLevel === DISPATCHER) &&
                                            <li className="d-none d-xl-block">
                                                <Link to='#'  onClick={()=>window.openModal('notesModal')}>
                                                    <img src={fileEdit} alt='' width={27} height={27}/>
                                                </Link>
                                            </li>
                                        }
                                    </ul>
                                </div>
                                <div className="header-profile">
                                    <div  className="header-profile-inner">
                                        <div className="dropdown">
                                            <div className="dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                                <span className={`${(userDetail && !userDetail.image) ? 'greenbg' : ''}`}>
                                                    {userDetail && userDetail.image && imageUrl ? (
                                                        <figure className="mb-0"><img src={`${imageUrl}${userDetail.image}`} alt="User profile" onError={(event) => event.target.src = noImageUrl } /></figure>
                                                    ) : (
                                                        upperCaseOfName(userDetail.first_name ? userDetail.first_name : "", userDetail.last_name ? userDetail.last_name : "")
                                                    )}
                                                </span>
                                                <div className="profile-info">
                                                    <strong className="profile-name d-block">{(userDetail && userDetail.full_name) ? userDetail.full_name : ""}</strong>
                                                </div>
                                            </div>
                                            <ul className="dropdown-menu">
                                                <li><Link className="dropdown-item" to="/edit-profile">{translate("header.update_profile")} </Link></li>
                                                {userDetail.login_token && (
                                                    <li>
                                                        <a
                                                            className="dropdown-item"
                                                            href={`${process.env.REACT_APP_SUBCONTRACTOR_URL}system-login?auth=${userDetail.login_token}`}
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                        >
                                                            {translate("header.subcontractor")}
                                                        </a>
                                                    </li>
                                                )}
                                                <li className='d-flex align-items-center justify-content-between'>
                                                    <span>Dark mode</span>
                                                    <div className="visibility-mode">
                                                        <input
                                                            type="checkbox"
                                                            id="switch"
                                                            checked={this.state.isDarkMode}
                                                            onChange={this.toggleDarkMode}
                                                        />
                                                        <label htmlFor="switch">Toggle</label>
                                                    </div>
                                                </li>
                                                <li><Link className="dropdown-item" to="#" onClick={() => this.Logout()}>{translate("header.logout")}</Link></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="filter-box calendar--filter">
                        <div className="accordion" id="accordionHeaderFilter">
                            <div className="accordion-item">
                                <div id="collapseHeaderFilter" className="accordion-collapse collapse" data-bs-parent="#accordionHeaderFilter">
                                    <div className="accordion-body">
                                        <div className="filter-box-header d-flex justify-content-between mb-3">
                                            <h3 className="mb-0">{translate('system.filter')}</h3>
                                            <Link to="#" className="btn-filter-close" data-bs-toggle="collapse" data-bs-target="#collapseHeaderFilter" aria-expanded="true" aria-controls="collapseHeaderFilter">
                                                <img src={closeImg} alt="Img" width={20} height={20} />
                                            </Link>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-4 col-md-6 col-sm-6">
                                                <div className="form-group">
                                                    <label htmlFor="location" className="form-label">{translate('calendar.location')}</label>
                                                    <Select
														className="custom-react-select"
														inputId="search_by_location"
														value={filterStats["location"] ? filterStats["location"] :null}
														options={locationList}
														onChange={this.handleChange("location")}
														closeMenuOnSelect={true}
														isClearable={true}
														isMulti
														placeholder={translate('calendar.search_by_location')}
													/>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-6 col-sm-6">
                                                <div className="form-group">
                                                    <label htmlFor="search_department" className="form-label">{translate('calendar.department')}</label>
                                                    <Select
                                                        className="custom-react-select"
                                                        inputId="search_department"
                                                        value={filterStats.department ? filterStats.department: null}
                                                        options={(filterStats.location && Array.isArray(filterStats.location)) ? DEPARTMENT_DROPDOWN.filter(dept =>
                                                            filterStats.location.some(loc => loc.department && loc.department.includes(dept.value))
                                                        ) : []}
                                                        onChange={this.handleChange("department")}
                                                        closeMenuOnSelect={true}
                                                        isMulti
                                                        placeholder={translate('calendar.search_by_department')}
                                                        isClearable={true}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-6 col-sm-6">
                                                <div className="form-group">
                                                    <label htmlFor="search_project_manager" className="form-label">{translate('calendar.project_manager')}</label>
                                                    <Select
                                                        className="custom-react-select"
                                                        value={filterStats.project_manager  ? filterStats.project_manager : null}
                                                        options={proManagerList}
                                                        onChange={this.handleChange("project_manager")}
                                                        closeMenuOnSelect={true}
                                                        placeholder={translate('calendar.search_by_project_manager')}
                                                        isClearable={true}
                                                        isMulti
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-6 col-sm-6">
                                                <div className="form-group">
                                                    <label htmlFor="search_foreman" className="form-label">{translate('calendar.foreman')}</label>
                                                    <Select
                                                        className="custom-react-select"
                                                        value={filterStats.foreman  ? filterStats.foreman : null}
                                                        options={foremanList}
                                                        onChange={this.handleChange("foreman")}
                                                        closeMenuOnSelect={true}
                                                        placeholder={<label htmlFor="foreman">{translate('calendar.search_by_foreman')}</label>}
                                                        isClearable={true}
                                                        isMulti
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-s col-md-6 col-sm-6">
                                                <div className="advanced-btn">
                                                    <button className='d-flex align-items-center w-100 btn' type="button" data-bs-toggle="collapse" data-bs-target="#advanced-filter" aria-expanded="false" aria-controls="advanced-filter">
                                                    {translate('calendar.advanced_filter')} <img src={arrowDown} alt="img" />
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="advanced-filter-wrap mt-3">
                                            <div className="row collapse" id="advanced-filter">
                                                <div className="col-lg-3 col-md-6 col-sm-6">
                                                    <div className="form-group">
                                                        <label htmlFor="search_job_number" className="form-label">{translate('calendar.job_number')}</label>
                                                        <Select
                                                            className="custom-react-select"
                                                            value={filterStats.job_number  ? filterStats.job_number : null}
                                                            options={jobNumberList}
                                                            onChange={this.handleChange("job_number")}
                                                            closeMenuOnSelect={true}
                                                            placeholder={translate('calendar.search_by_job_number')}
                                                            isClearable={true}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-3 col-md-6 col-sm-6">
                                                    <div className="form-group">
                                                        <label htmlFor="search_work_order" className="form-label">{translate('calendar.work_order')}</label>
                                                        <AsyncPaginate
                                                            className="custom-react-select"
                                                            inputId="search_work_order"
                                                            value={filterStats.work_order  ? filterStats.work_order : null}
                                                            key={filterStats.job_number  ? filterStats.job_number.value : null}
                                                            loadOptions={this.getSearchDropDownList}
                                                            onChange={this.handleChange("work_order")}
                                                            additional={{ page: 1, type: "workOrder"}}
                                                            placeholder={translate('calendar.search_by_work_order')}
                                                            isClearable={true}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-3 col-md-6 col-sm-6">
                                                    <div className="form-group">
                                                        <label htmlFor="search_employees" className="form-label">{translate('calendar.crew_member')}</label>
                                                        <AsyncPaginate
                                                            className="custom-react-select"
                                                            inputId="search_employees"
                                                            value={filterStats.employees  ? filterStats.employees : null}
                                                            loadOptions={this.getSearchDropDownList}
                                                            onChange={this.handleChange("employees")}
                                                            additional={{ page: 1, type: "crewMember"}}
                                                            placeholder={translate('calendar.search_by_crew_member')}
                                                            isClearable={true}
                                                            isMulti
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-md-6 col-sm-6">
                                                    <div className="form-group">
                                                        <label htmlFor="skill_set" className="form-label">{translate('calendar.skill_set')}</label>
                                                        <AsyncPaginate
                                                            className="custom-react-select"
                                                            inputId="skill_set"
                                                            value={filterStats.skill_set  ? filterStats.skill_set : null}
                                                            loadOptions={this.getSearchDropDownList}
                                                            onChange={this.handleChange("skill_set")}
                                                            additional={{ page: 1, type: "skill_set"}}
                                                            isMulti
                                                            placeholder={<label htmlFor="skill_set">{translate('calendar.search_skill_set')}</label>}
                                                            isClearable={true}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-3 col-md-6 col-sm-6">
                                                    <div className="form-group">
                                                        <label htmlFor="search_equipment" className="form-label">{translate('calendar.equipment')}</label>
                                                        <Select
                                                            className="custom-react-select"
                                                            value={filterStats.equipment  ? filterStats.equipment : null}
                                                            options={filterAssetList}
                                                            onChange={this.handleChange("equipment")}
                                                            placeholder={translate('calendar.search_by_equipment')}
                                                            isClearable={true}
                                                            isMulti
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-3 col-md-6 col-sm-6">
                                                    <div className="form-group">
                                                        <label htmlFor="search_subcontractor" className="form-label">{translate('calendar.subcontractor')}</label>
                                                        <Select
                                                            className="custom-react-select"
                                                            value={filterStats.subcontractor_id  ? filterStats.subcontractor_id : null}
                                                            options={subcontractorList}
                                                            onChange={this.handleChange("subcontractor_id")}
                                                            placeholder={translate('calendar.search_by_subcontractor')}
                                                            isClearable={true}
                                                            isMulti
                                                        />
                                                    </div>
                                                </div>

                                                {filterStats.department && filterStats.department.value === FIBER_DEPARTMENT ?
                                                    <div className="col-lg-3 col-md-6 col-sm-6">
                                                        <div className="form-group">
                                                            <label htmlFor="fiber_task" className="form-label">{translate('calendar.fiber_task')}</label>
                                                            <AsyncPaginate
                                                                className="custom-react-select"
                                                                inputId="fiber_task"
                                                                value={filterStats.fiber_task  ? filterStats.fiber_task : null}
                                                                loadOptions={this.getSearchDropDownList}
                                                                onChange={this.handleChange("fiber_task")}
                                                                additional={{ page: 1, type: "fiber_task"}}
                                                                placeholder={<label htmlFor="fiber_task">{translate('calendar.search_by_fiber_task')}</label>}
                                                                isClearable={true}
                                                            />
                                                        </div>
                                                    </div>
                                                :null}
                                            </div>
                                        </div>
                                        <div className="d-flex">
                                            <button className="btn btn-fill me-3" onClick={()=>{ this.getCalendarResourceList() }} >{translate("system.submit")}</button>
                                            <button className="btn btn-outline" onClick={()=>{ this.setState({filterStats: {}},()=>{ this.getCalendarResourceList() }) }}>{translate("system.reset")}</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {is_loading ?
                        null
                    :
                        isMobileScreen ?
                            <>
                                <div className="calender-mobile">
                                    <div className="container">
                                        <div className="calender-job-detail">
                                            {eventList.length ? (
                                                eventList.map((event, index) => {
                                                    // Determine if foreman is disabled
                                                    let isDisableForeman = event && event.is_disable ? true : false;
                                                    let isPTO           = (event && event.resourceId && (event.resourceId === CALENDAR_PTO)) ? true :false;
                                                    let isSubcontractor =   event && event.resourceId && (event.resourceId === CALENDAR_SUB_CONTRACTOR)? true :false;
                                                    let eventDate       = formatDate(event.date, CALENDAR_DATE_TIME_FORMAT);

                                                    return (
                                                        <div
                                                            className={
                                                                "timeline-event " +
                                                                (event.is_draft ? "draft-shift" :
                                                                    (event.shift === SHIFT_DAY || isPTO) ? "day-shift" : isDisableForeman ? "disable-foreman" :"night-shift")
                                                            }
                                                            key={event._id+index}
                                                        >
                                                            <div className="round-all event-item" onClick={() => { if(!isSubcontractor) this.eventClicked(schedulerData, event)} }>
                                                                <h3>{event.foreman_name ? event.foreman_name : ""}</h3>
                                                                <p
                                                                    className='text-primary'
                                                                    dangerouslySetInnerHTML={{ __html: event.title }}
                                                                ></p>
                                                                {(event && event.fiber_task_name) ? <div className='fiber_task_text'>{event.fiber_task_name}</div> : ""}
                                                                <div className="row crew-members">
                                                                    {event.crewMembers && event.crewMembers.length ? (
                                                                        event.crewMembers.map((crew, crewIndex) => (
                                                                            <div className="col-sm-4 col-6" key={crew._id+crewIndex} onClick={() => { if(isSubcontractor && crew.root_id) this.eventClicked(schedulerData, {_id: crew.root_id})}}>
                                                                                <div className={(crew.termination_date) ? "terminate-emp terminate-emp-mob" : ""} style={{ backgroundColor: crew.bgColor ? crew.bgColor : "#fff" }}>
                                                                                    <img src={crew.image} alt="img" />
                                                                                    <span dangerouslySetInnerHTML={{__html: crew.groupName || "" }}></span>
                                                                                </div>
                                                                            </div>
                                                                        ))
                                                                    ) : null}
                                                                </div>
                                                                <div className="row assets">
                                                                    {event.assets && event.assets.length ? (
                                                                        event.assets.map((asset, assetIndex) => (
                                                                            <div className="col-sm-4 col-6" key={asset._id+assetIndex}>
                                                                                <div className={(!asset.is_active) ? "terminate-emp terminate-emp-mob" : ""} style={{ backgroundColor: asset.bgColor }}>
                                                                                    <img src={asset.image} alt={asset.groupName} />
                                                                                    <span>{asset.groupName}</span>
                                                                                </div>
                                                                            </div>
                                                                        ))
                                                                    ) : null}
                                                                </div>
                                                                {(!isPTO && !isDisableForeman && !isSubcontractor) &&
                                                                    <div className="shifts-icon">
                                                                        <figure>
                                                                            <img src={(event.shift === SHIFT_DAY) ? sunIcon : moonIcon} alt="img" />
                                                                        </figure>
                                                                        <span>{event && event.start_time ? convertTo12HourFormat(event.start_time) : ""}</span>
                                                                    </div>
                                                                }
                                                            </div>
                                                            {(manageJobAction(eventDate).edit && !isMonthView && isAttachEditBtnShow && !isDisableForeman && !isSubcontractor && permissionLevel !== FOREMAN) &&
                                                                <div className="job-edit-btns">
                                                                    <button className={`kebab-edit-btn kebab-edit-btn-${event._id+index}`} onClick={() => this.toggleJobBtns(event._id)} >
                                                                        <img src={kebabIcon} alt="kebab icon" />
                                                                    </button>

                                                                    <div className={`d-flex align-items-center job-btns-box ${this.state.visibleJobBtns === event._id ? 'show' : ''}`}>
                                                                        <button type='button' className="btns edit-btn" onClick={() => { this.openEditModal(event.foreman_id, event._id)}} >
                                                                            <img src={editIcon} alt="img"/>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            }
                                                        </div>
                                                    );
                                                })
                                            ) : (
                                                <h3 className="text-center pt-5">{translate('calendar.no_jobs_available')}</h3>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </>
                        :
                            <Row>
                                <Col span={20}>
                                    <Scheduler
                                        schedulerData={viewModel}
                                        handlieWorkView={this.handlieWorkView}
                                        handleFullScreen={this.handleFullScreen}
                                        handleCollapsView={this.handleCollapsView}
                                        handleHideEquipment={this.handleHideEquipment}
                                        prevClick={this.prevClick}
                                        nextClick={this.nextClick}
                                        onSelectDate={this.onSelectDate}
                                        onViewChange={this.onViewChange}
                                        updateEventCalendar={this.updateEventCalendar}
                                        eventItemClick={this.eventClicked}
                                        openEditModal={this.openEditModal}
                                        isMonthView={isMonthView}
                                        viewEventClick={!isMonthView ? this.ops1: undefined}
                                        moveEvent={!isReadOnly ? this.moveEvent : undefined}
                                        newEvent={!isReadOnly ? this.newEvent : undefined}
                                        subtitleGetter={this.subtitleGetter}
                                        dndSources={dndSources}
                                        toggleExpandFunc={this.toggleExpandFunc}
                                        nonAgendaCellHeaderTemplateResolver = {this.nonAgendaCellHeaderTemplateResolver}
                                        handleHeaderFilter= {this.handleHeaderFilter}
                                        openJobLogModal= {this.openJobLogModal}
                                        handleDeletejob= {this.handleDeletejob}
                                    />
                                    <Link to='#' onClick={()=>window.removeClassInElement('body','side-menu-hide')} className='sidebar-toggle-btn fixed-btn'>
                                        <img src={arrowRight} alt='Search' width={20} height={20}/>
                                    </Link>
                                </Col>
                                <Col span={4}>
                                    <div className='source-list'>
                                        <div className='source-list-header d-flex justify-content-between align-items-center'>
                                            <h3>{translate("calendar.availability")}</h3>
                                            <Link to='#' className='reset-icon ms-auto me-3' onClick={()=> {this.refreshMemberAssetList()}}>
                                                <img src={reset} alt='Reset' width={18} height={18}/>
                                            </Link>
                                            <Link to='#' onClick={()=>window.addClassInElement('body','side-menu-hide')} className='sidebar-toggle-btn'>
                                                <img src={arrowRight} alt='Search' width={20} height={20}/>
                                            </Link>
                                        </div>

                                        {!isReadOnly && (
                                            <>
                                                {/* Render the Crew member list component */}
                                                {memberList}

                                                {/* Render the Asset/Shop list component */}
                                                {assetList}
                                            </>
                                        )}
                                    </div>
                                </Col>
                            </Row>
                    }
                </div>
                {/* Render the TaskModal component */}
                {isOpen &&
                    <TaskModal dateObj={dateObj} updateEventCalendar={this.updateEventCalendar} jobDetails={this.state.jobDetailsForEditOrCopy} handleCloseTaskModal={this.handleCloseTaskModal} />
                }
                <NotesModal />
                {isAssignModal &&
                    <AssignModal jobOptions={jobOptions} jobId={jobId} foremanId={foremanId} type={type} memberAsseId={memberAsseId} assignMemberAssetForJob={this.assignMemberAssetForJob}  />
                }
                {this.state.showEventDetailsModal &&
                    <EventItemDetailsModal eventId={this.state.eventDetailsId} isForemanDisable={is_foreman_disable} closeJobDetailModal={this.closeJobDetailModal} openEditModal={this.openEditModal} isMobileScreen={isMobileScreen} />
                }
                {isYardOrPtoOpen &&
                    <AddPtoYardJob dateObj={dateObj} updateEventCalendar={this.updateEventCalendar} jobDetails={this.state.jobDetailsForEditOrCopy} handleCloseTaskModal={this.handleCloseTaskModal} />
                }
                {isSubcontractorOpen &&
                    <AddSubContractorJob dateObj={dateObj} updateEventCalendar={this.updateEventCalendar} jobDetails={this.state.jobDetailsForEditOrCopy} handleCloseTaskModal={this.handleCloseTaskModal} />
                }
                {isAssginPaidOrUnpaid &&
                    <PaidUnpaidAssignModal assignEmployeeForPtoJob={this.assignEmployeeForPtoJob}  handleCloseTaskModal={this.handleCloseTaskModal} />
                }
                {isMoveToShopModal &&
                    <MoveShopModal movedAssetId={movedAssetId} refreshAssetOnMoveToShope={this.refreshAssetOnMoveToShope} />
                }
                {this.state.eventLogId &&
                    <JobLogsModal eventId={this.state.eventLogId} handleCloseJobLogModal={this.handleCloseTaskModal}  />
                }
                {this.state.showDeleteModal && this.state.deletedJobGroupId &&
                    <DeleteModal deletedJobGroupId={this.state.deletedJobGroupId} handleCloseTaskModal={this.handleCloseTaskModal} updateEventCalendar={this.updateEventCalendar} />
                }
                <AssetDetailModal assetDetails={assetDetails} />
            </div>
        )
    }

    handleDeletejob = (jobGroupId) =>{
        this.setState({showDeleteModal: true, deletedJobGroupId: jobGroupId},()=>{
            window.openModal('deleteModal');
        });
    }

    handleCollapsView = (isCollapsAll)=> {
        this.setState({
            isCollapsView  : isCollapsAll,
            isMonthView    : isCollapsAll
        },()=>{
            this.getCalendarResourceList();
        });
    }

    /**Set function for hide equipments */
    handleHideEquipment = (isHidequipment)=> {
        window.showLoader();
        this.setState({
            isHidequipment  : isHidequipment,
        },()=>{
            this.getCalendarTaskList();
        });
    }

    handlieWorkView = (schedulerData,isWorkWeek)=> {
        schedulerData.workView(isWorkWeek);
        schedulerData.setEvents(this.state.eventList);
        this.setState({
            isWeekend : isWorkWeek,
            viewModel: schedulerData
        });
    }

    handleFullScreen = (schedulerData)=>{
        schedulerData.setEvents(this.state.eventList);
        this.setState({
            viewModel: schedulerData
        });
    }

    prevClick = (schedulerData)=> {
        window.showLoader();
        schedulerData.prev();
        schedulerData.setEvents(this.state.eventList);
        this.setState({
            viewModel: schedulerData,
            startWeekDate: schedulerData.startDate,
            endWeekDate: schedulerData.endDate,
        },()=>{
            this.handlieWorkView(schedulerData,this.state.isWeekend)
            this.getCalendarResourceList();
        })
    }

    nextClick = (schedulerData)=> {
        window.showLoader();
        schedulerData.next();
        schedulerData.setEvents(this.state.eventList);
        this.setState({
            viewModel: schedulerData,
            startWeekDate: schedulerData.startDate,
            endWeekDate: schedulerData.endDate,
        },()=>{
            this.handlieWorkView(schedulerData,this.state.isWeekend)
            this.getCalendarResourceList();
        });
    }

    /**
     * Handles changes in the view of the scheduler.
     *
     * @param {Object} schedulerData - The scheduler data object.
     * @param {Object} view - The new view object containing view type and settings.
    */
    onViewChange = (schedulerData, view) => {
        window.showLoader();
        schedulerData.setViewType(view.viewType, view.showAgenda, view.isEventPerspective);
        schedulerData.config.creatable = !view.isEventPerspective;
        schedulerData.setEvents(this.state.eventList);
        this.handleCollapsView(false);
        this.setState({
            viewModel       : schedulerData,
            startWeekDate   : schedulerData.startDate,
            endWeekDate     : schedulerData.endDate,
            isMonthView     : (view.viewType === 2) ? true : false
        },()=>{
            this.getCalendarResourceList();
        });
    } // end onViewChange()

    /**
     * Handles the date selection in the scheduler.
     *
     * @param {Object} schedulerData - The scheduler data object.
     * @param {Date} date - The selected date.
     * @param {boolean} isMemberAssetFilter - Indicates if the member asset filter is applied.
    */
    onSelectDate = (schedulerData, date, isMemberAssetFilter) => {
        let isReadOnly = schedulerData.is_read_only;
        schedulerData.localeMoment.locale('en-gb');
        schedulerData.setDate(date);
        schedulerData.setEvents(this.state.eventList);
        this.setState({
            viewModel: schedulerData,
            calendarDefaultDate: date,
            startWeekDate: this.state.isMobileScreen ? date : schedulerData.startDate,
            endWeekDate: this.state.isMobileScreen ? date : schedulerData.endDate,
            isMobileDate: this.state.isMobileScreen ? true: false
        },()=>{

            schedulerData.localeMoment.locale('en');
            this.handlieWorkView(schedulerData,this.state.isWeekend)
            if(isMemberAssetFilter && !isReadOnly){
                this.assetsChildRef.current.handleAssetListByCalendarDate();
                this.memberChildRef.current.handleCrewListByCalendarDate();
            }else{
                this.getCalendarResourceList();
            }
        });
    } // end onSelectDate()

    /**
     * Handles the event click in the scheduler.
     *
     * @param {Object} schedulerData - Data related to the scheduler.
     * @param {Object} event - The clicked event object.
    */
    eventClicked = (schedulerData, event) => {
        if(event && event._id){
            this.setState({showEventDetailsModal: true, eventDetailsId: event._id,is_foreman_disable : event && event.is_disable ? event.is_disable : false},()=>{
                window.document.body.classList.add("modal-open");
		        window.document.body.classList.add("overflow-hidden");
		        window.showLoader();
            });
        }
    };// end eventClicked()

    /**
     * Opens the edit modal for a job, fetching necessary details from the backend.
     *
     * @param {string} foremanId - The ID of the foreman.
     * @param {string} jobId - The ID of the job.
     * @param {boolean} [isCopy=false] - Indicates if the job is being copied.
     * @param {string} date - The date associated with the job.
     * @param {string} slotParentName - The name of the parent slot.
    */
    openEditModal = (foremanId, jobId, isCopy = false, date, slotParentName, isMoveEvent = false, isExtendJob= false) => {
        this.closeJobDetailModal();
        if(foremanId && jobId){
            window.showLoader();
            callBackendAPI([{ model: 'calendar', method: 'calendarEventDetails', job_id: jobId, foreman_id: foremanId }]).then(response => {
                if(response.success && response.data && response.data[0] && response.data[0].result){

                    let isMainJob       =  ([CALENDAR_YARD, CALENDAR_PTO, CALENDAR_SUB_CONTRACTOR].indexOf(foremanId) === -1) ?  true : false;
                    let isYardPTOJob    =  ([CALENDAR_YARD, CALENDAR_PTO].indexOf(foremanId) !== -1) ?  true : false;
                    let isSubcontractor =  foremanId === CALENDAR_SUB_CONTRACTOR ?  true : false;

                    let data = response.data[0].result;
                    let jobGroupId = (data.job_group_id) ? data.job_group_id : '';
                    slotParentName = slotParentName ? slotParentName : (data && data.foreman_name ? data.foreman_name : "");
                    let empType = "";
                    if(data.unpaid_employee_ids && data.unpaid_employee_ids.length) empType = "unpaid";
                    if(data.paid_employee_ids && data.paid_employee_ids.length) empType = "paid";

                    this.setState({
                        dateObj                 :  isYardPTOJob ? {slot_type: CALENDAR_YARD === foremanId ? 'yard' :'pto', emp_type: empType} : {foreman_id:foremanId, parent_name: slotParentName} ,
                        jobDetailsForEditOrCopy :  {...response.data[0].result, ...{isCopy: isCopy ? isCopy :false, isEdit: !isCopy ? true :false, selected_date: date, file_url:  response.data[0].fileUrl, is_move_event: isMoveEvent, is_extend_job: isExtendJob, extended_job_group_id:  jobGroupId}},
                        isOpen                  :  isMainJob,
                        isYardOrPtoOpen         :  isYardPTOJob,
                        isSubcontractorOpen     :  isSubcontractor,
                    },()=>{
                        if(isMainJob)       window.openModal('myModal');
                        if(isYardPTOJob)    window.openModal('addPtoYardJob');
                        if(isSubcontractor) window.openModal('assSubContractorJob');

                        /** Hide loader */
                        window.hideLoader();
                    });
                }else{
                    /** Hide loader */
                    window.hideLoader();
                }
            }).catch(err => console.log(err));
        }
    }; // end openEditModal()

    /**
     * Closes the job detail modal by updating the state and
     * removing modal-related classes from the body.
    */
    closeJobDetailModal = ()=>{
        this.setState({showEventDetailsModal: false, eventDetailsId: ""},()=>{
            window.document.body.classList.remove("modal-open");
            window.document.body.classList.remove("overflow-hidden");
        });
    }// end closeJobDetailModal()

    /**Function for Create new job */
    newEvent = (schedulerData, foremanId, slotName, start, end, type, item, slotParentId, slotParentName, isDrag) => {
        let currentDate = formatDate(new Date(), CALENDAR_DATE_TIME_FORMAT);
        let startDate = formatDate(start, CALENDAR_DATE_TIME_FORMAT);
        let prevDate  = manageJobAction(start).drag;

        /**Set condition for not add task for previous date for creating new task without draging  */
        if((startDate < currentDate || end < currentDate) && isDrag === undefined){
            alert(translate("calendar.you_can_not_add_employee_on_previous_date"))
            return false;
        }

        /**  Return false if the item is defined and the type is "subcontractor" */
        if((type === "subcontractor") && item !== undefined) return false;

        /**
         * Returns false if the type is "pto" and the item is defined,
         * and its state type is "asset".
        */
        if(type === "pto" && item && item !== undefined && item.state && item.state.type && item.state.type === "asset") return false;

        let allEvents = (schedulerData && schedulerData.events && schedulerData.events.length) ? schedulerData.events : [];

        let jobArr = [];
        if(allEvents.length){
            jobArr = allEvents.filter(item => (item.start === start) && foremanId === item.resourceId && !item.is_disable).map(item => {
                return {
                'value': item._id,
                'label': item.label ? item.label : item.title,
                'foreman_id': item.foreman_id,
                'is_disable': item.is_disable || false
                };
            });
        }

        /**
         * Set condition to prevent dragging an employee/equipment
         * to a previous date where no job is available.
        */
        if((startDate < currentDate) && jobArr.length === 0 && isDrag && isDrag !== undefined){
            alert(translate("calendar.you_can_not_add_employee_on_previous_date"))
            return false;
        }

        /**
         * Set condition to prevent dragging an employee/equipment
         * to a date that is 14 days before the current date
         * where a job is available.
        */
        if(!prevDate && isDrag && isDrag !== undefined){
            alert(translate("calendar.you_can_not_add_employee_on_previous_date"))
            return false;
        }

        if(jobArr.length > ACTIVE && item !== undefined){
            this.setState({
                jobOptions  : (type !== "pto") ? jobArr : "",
                jobId       : "",
                foremanId   : "",
                type        : ((item && item.state.type && item.state.type === "asset") ? "asset" : "crew"),
                memberAsseId: (item && item.id) ? item.id: "",
                dateObj     : {start_date: start, end_date: end, drag_item: item, foreman_id:slotParentId, parent_name: slotParentName, slot_type: type, jobArr: jobArr},
                isAssignModal : (type !== "pto") ? true: false,
                isAssginPaidOrUnpaid :  (type && (type === "pto" && item !== undefined)) ? true : false
            },()=>{
                if(type === "pto"){
                    window.openModal('assignPaidUnpdaidModal');
                }else{
                    window.openModal('assignModal');
                }
            });
        }else if(jobArr.length === ACTIVE && item !== undefined && (type !== "pto") && !jobArr[0].is_disable){
            let assType        = ((item && item.state.type && item.state.type === "asset") ? "asset" : "crew");
            this.assignMemberAssetForJob(jobArr[0].value,jobArr[0].foreman_id,assType,item.id)
        }else{
            this.setState({
                viewModel   : schedulerData,
                dateObj     : {start_date: start, end_date: end, drag_item: item, foreman_id:slotParentId, parent_name: slotParentName, slot_type: type, jobArr: jobArr},
                isOpen      : (type === "pto" || type === "yard" || type === "subcontractor") ?  false : true,
                isYardOrPtoOpen      : (type === "yard") ?  true : ((type === "pto" && item === undefined) ? true: false),
                isSubcontractorOpen  : (type === "subcontractor") ?  true : false,
                isAssginPaidOrUnpaid :  (type && (type === "pto" && item !== undefined)) ? true : false
            },()=>{
                if(type && type === "pto" && item !== undefined){
                    window.openModal('assignPaidUnpdaidModal');
                }else{
                    if(type && (type === "pto" && item !== undefined)){
                        window.openModal('assignPaidUnpdaidModal')
                    }else if(type === "pto" || type === "yard"){
                        window.openModal('addPtoYardJob')
                    }else if(type === "subcontractor"){
                        window.openModal('assSubContractorJob')
                    }
                    else{
                        window.openModal('myModal')
                    }
                }
            });
        }
    }// end newEvent()

    /**Function for move/copy job */
    moveEvent = (schedulerData, event, slotId, slotName, start, end, type, slotParentId, slotParentName) => {
        const currentDate = formatDate(new Date(), CALENDAR_DATE_TIME_FORMAT);

        /**Set condition for not add task for previous date  */
        if(end < currentDate){
            alert(translate("calendar.you_can_not_move_on_previous_date"))
            return false;
        }

        let isMainJob       =  ([CALENDAR_YARD, CALENDAR_PTO,CALENDAR_SUB_CONTRACTOR].indexOf(event.foreman_id) === -1) ?  true : false;
        if(!isMainJob && type === undefined) return false;

        if(type && (type === "subcontractor") && (event.foreman_id === CALENDAR_PTO || event.foreman_id === CALENDAR_SUB_CONTRACTOR)) return false;
        if(type && (type === "pto" || type === "yard")) return false;

        let message = translate("calendar.are_you_sure_you_want_to_move_or_clone_this_job");
        let confirmation = customConfirmBox("",translate(message),true);
        confirmation.then((status) => {
            if(status === "clone" || status === "extend"){
                let isExtend = (status === "extend") ? true : false;
                if(isExtend){
                    this.openEditModal(slotId,event._id, true,start, slotParentName, true, true);
                }else{
                    this.openEditModal(slotId,event._id,true,start, slotParentName, true);
                }
            }else if(status === "move"){
                window.showLoader();
                callBackendAPI([{model: 'jobs', method: 'moveJob', job_id: event._id, foreman_id: slotParentId, date: formatDate(start,CALENDAR_DATE_TIME_FORMAT)}]).then(response => {
                    if (response.success && response.data[0].status === STATUS_SUCCESS) {
                        window.hideLoader()
                        reFormatSuccess(response.data[0].message);
                        this.updateEventCalendar(null,"calendar");
                    }else{
                        let tmpErrors =  reFormatErrors(response.data[0].message);
                        let isForceAssign = (tmpErrors &&  tmpErrors != null && tmpErrors.force_resource_id ? tmpErrors.force_resource_id : "");
                        window.hideLoader();
                        if(isForceAssign){
                            let confirmation = customConfirmBox(isForceAssign,"",false,true);
                            confirmation.then((confirmed) => {
                                if (confirmed) {
                                    callBackendAPI([{model: 'jobs', method: 'moveJob', job_id: event._id, foreman_id: slotParentId, date: formatDate(start,CALENDAR_DATE_TIME_FORMAT), force_assign: true }]).then(response => {
                                        if (response.success && response.data[0].status === STATUS_SUCCESS) {
                                            window.hideLoader()
                                            reFormatSuccess(isForceAssign);
                                            this.updateEventCalendar(null,"calendar");
                                        }else{
                                            window.hideLoader();
                                        }
                                    }).catch(err => console.log(err));
                                }
                            });
                        }
                    }
                }).catch(err => console.log(err));
            }
        });
    }// end moveEvent()

    subtitleGetter = (schedulerData, event) => {
        return schedulerData.isEventPerspective ? schedulerData.getResourceById(event.resourceId).name : event.groupName;
    }

    toggleExpandFunc = (schedulerData, slotId) => {
        schedulerData.toggleExpandStatus(slotId);
        this.setState({
            viewModel: schedulerData
        });
    }
}
export default withDragDropContext(DragAndDrop)
