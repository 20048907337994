import '@availity/block-ui/src/BlockUi.css';
import "react-datepicker/dist/react-datepicker.css";

import React, { Component } from "react";
import Select from 'react-select';
import DatePicker from "react-datepicker";

import { callBackendAPI, handleFormValidation,callBackendAPIAndImageUpload,reFormatErrors,reFormatSuccess,handleFormInputs } from './../../helpers/common';
import {STATUS_SUCCESS, RADIO_YES, RADIO_NO, DEPARTMENT_DROPDOWN, ACTIVE }  from './../../config/global_constants';
import { translate  } from './../../helpers/intl_helpers';
import { UserDetails }  from './../../UserDetails';

class AddSubContratorForm extends Component {
	static contextType = UserDetails;

	constructor(props) {
        super(props)

		// Access context here

        this.state = {
			isLoading		: true,
			errors			: {},
			fields			: {
				request_date: {value: new Date()},
				is_job_number:  {value: String(RADIO_YES)},
			},
			submitted		: false,
			fileUrl			: "",
			imageSrc		: "",
			croppedImage	: "",
			fullName 		: "",
			serviceTypeList : [],
            jobNumberList   : [],
            userArr   		: [],
        };

		this.onCropComplete				= this.onCropComplete.bind(this);
		this.handleFormInputs 			= this.handleFormInputs.bind(this);
		this.handleFormSubmit 			= this.handleFormSubmit.bind(this);
    }

	/** This function invoked immediately after a component is mounted.
	 * Its use to manage initial data fetch.
	 */
	componentDidMount() {

		window.showLoader();

		let apiParams = [
			{ model: 'home', 	 method: 'masterList', type: ["service_type"] },
            { model: 'calendar', method: 'cannonJobList' },
		];

		/** Call API */
		callBackendAPI(apiParams).then(response => {
			if(response.success && response.data[0].status === STATUS_SUCCESS && response.data[0].result) {
				let tmpstamp	=	{
					serviceTypeList		: response.data[0].result.service_type,
					jobNumberList       : response.data[1].result,
				};
				this.setState(tmpstamp,()=>{
					window.hideLoader();
				});
			}
		}).catch(err => console.log(err));
    }

	/**
	* For manage form submit event
	*
	* @param e 	as 	current form object
	*
	* @return null
	*/
	handleFormSubmit(e){
		e.preventDefault();

		const {company_name,contact_name,email,mobile_number,request_date,is_job_number,job_number,project_name,estimated_start_date,service_type,requestor_name,department,department_manager,justification} = this.state.fields;

		let serviceTypeArr   = [];
		let departmentManagerArr   = [];
		if(service_type && service_type.length) serviceTypeArr = service_type.map(element => element.value);
		if(department_manager && department_manager.length) departmentManagerArr = department_manager.map(element => element.value);
		this.setState({
			submitted: true,
			fields      :   {
                ...this.state.fields,
                ...{
                    service_type_err: {value: serviceTypeArr.length ? serviceTypeArr : null},
                    department_manager_err: {value: departmentManagerArr.length ? departmentManagerArr : null},
                }
            }
		}, () =>{

			/** Api Requested Data **/
			let params = [{
				model				: 	'employee',
				method				: 	'addSubcontractorRequest',
				company_name 		: 	company_name && company_name.value  ? company_name.value	:"",
				contact_name		: 	(contact_name && contact_name.value)			?	contact_name.value		:"",
				email				: 	(email && email.value)							?	email.value				:"",
				mobile_number		: 	(mobile_number && mobile_number.value)			?	mobile_number.value		:"",
				request_date		: 	(request_date && request_date.value)			?	request_date.value		:"",
				is_job_number		:	(is_job_number && is_job_number.value)			?	is_job_number.value		:"",
				job_number			: 	(job_number && job_number.value)				?	job_number.value		:"",
				project_name			: 	(project_name && project_name.value)					?	project_name.value			:"",
				estimated_start_date: 	(estimated_start_date && estimated_start_date.value)?	estimated_start_date.value	:"",
				service_type		: 	serviceTypeArr,
				requestor_id		: 	(requestor_name && requestor_name.value)		?	requestor_name.value		:"",
				department			:	(department && department.value)				?	department.value			:"",
				// department_manager	:	departmentManagerArr,
				justification		:	(justification && justification.value)			?	justification.value			:"",
			}];

			/** Validation Rules **/
			let validations = {
				company_name	: {"label": translate('company_name','Company Name'), "rules" :["required"]},
				contact_name		: {"label": translate('contact_name','Project Manager Name'), "rules" :["required"]},
				email				: {"label": translate('login.email','Email'), "rules" :["required", "email"]},
				mobile_number		: {"label": translate('mobile_number','Phone Number'), "rules" :["required"]},
				request_date		: {"label": translate('request_date','Request Date'), "rules" :["select"]},
				is_job_number		: {"label": translate('is_job_number','Is Job Number'), "rules" :["required"]},
				estimated_start_date: {"label": translate('estimated_start_date','Estimated Start Date'), "rules" :["select"]},
				service_type_err	: {"label": translate('service_type','Service Type'), "rules" :["select"]},
				department			: {"label": translate('department','Department'), "rules" :["select"]},
				justification		: {"label": translate('justification','Justification'), "rules" :["required"]},

			}

			if(is_job_number && is_job_number.value === String(RADIO_YES)){
                validations['job_number'] = { "label": translate('job.job_number'), "rules" :["select"] };
            }

			/** Handle validation and api response **/
			handleFormValidation(validations,this.state.fields, params, true,true,true).then(async (response) =>{
				if(!response.success){
					if(response.errors && response.data){

						this.setState({ errors : response.errors, submitted: false});
					}else if(response.errors){
						this.setState({ errors : response.errors, submitted: false})
					}
				}else{
					/** Call parent function */
					if(this.props.updateEventCalendar) this.props.updateEventCalendar(null,"calendar");
					/** Hide loader */
					this.closeModal();
				}

			}).catch(err => console.log(err));
		});
	}

	/**
	* For get users listing
	*/
    getUserListByDepartment = (fieldVal) =>{
		/** Call API for work order list */
	   	callBackendAPI([{ model: 'employee', method: 'getUsersList', department: fieldVal.value, is_all: true }]).then(response => {
		   	if(response.success && response.data[0].status === STATUS_SUCCESS && response.data[0].result){

			   	this.setState({
				   userArr: response.data[0].result,
			   	});
		   	}
	   }).catch(err => console.log(err));
   	}// end getUserListByDepartment()

	/**
	* For hide model
	*
	* @return null
	*/
	closeModal() {
		window.document.body.classList.remove("modal-open");
		window.document.body.classList.remove("overflow-hidden");

		this.props.requestModal(false);
	}// end closeModal()

	/**
	* For update state fields values
	*
	* @param e  	as current field object
	* @param field  as current field name
	*
	* @return null
	*/
	handleFormInputs(e, field){
		let response	=	handleFormInputs(e, field, this.state.fields);
		this.setState({ response });
	}// end handleFormInputs()


	/**
	 * Handle dropdown value state
	 */
	 handleChange = (fieldName) => (fieldVal) => {
		let newObj = {[fieldName]: fieldVal};

		let updatedFields = {...this.state.fields, ...newObj};

        if(fieldName === "department"){
			updatedFields['department_manager'] = null;
			this.getUserListByDepartment(fieldVal);
		}
		if(fieldName === "job_number"){
			console.log("fieldVal",fieldVal)
			updatedFields.project_name = (fieldVal && fieldVal.project_name) ? {value: fieldVal.project_name}  : "";
		}

        this.setState({fields: updatedFields});
    }; // end handleChange()

	onCropComplete = (croppedImage) => {
		this.setState({ croppedImage });
	};

	render() {
		const {fields,errors, jobNumberList, serviceTypeList,userArr} = this.state;
		const { userDetails } = this.context;
		fields['requestor_name'] = {value: userDetails._id, label:userDetails.full_name }

		return <>
			<div
				className={`modal fade show d-block subcontractor-modal`}
				id="adduserModal"
				tabIndex={-1}
				aria-labelledby="adduserModal"
				aria-hidden="true"
			>
				<div className="modal-dialog modal-dialog-centered modal-xl">
					<div className="modal-content">
						<button
							type="button"
							className="btn-close"
							data-bs-dismiss="modal"
							aria-label="Close"
							onClick={() => this.closeModal()}
						>
							X {translate("system.close")}
						</button>
						<div className="modal-body">
							<h2 className="modal-title" id="requestModalLabel">{translate("subcontractor.subcontractor_request")}</h2>
							<form className="request-field" onSubmit={(event) => this.handleFormSubmit(event, 'AddSubContractorJob')} noValidate>
								<div className="row">
									<div className="col-md-12 mb-4">
										<span className="fields-label-text">{translate("subcontractor.contact_information")}</span>
										<div className="fields-label">
											<div className="row">
												<div className="col-md-6 col-xl-4">
													<div className="form-group mb-3">
														<label className="mb-1 form-label" htmlFor="ticket_number3">{translate("subcontractor.subcontractor_company_name")}<span className="error text-danger">*</span></label>
														<input
															type="text"
															className="form-control input-with-label"
															name="company_name"
															id="company_name"
															placeholder="Company Name"
															value={fields.company_name ? fields.company_name.value : ""}
															onChange={(e) => this.handleFormInputs(e, 'company_name')}
														/>
														<span className="error text-danger">{errors["company_name"]}</span>
													</div>
												</div>
												<div className="col-md-6 col-xl-4">
													<div className="form-group mb-3">
														<label className="mb-1 form-label" htmlFor="ticket_number3">{translate("subcontractor.project_manager")}<span className="error text-danger">*</span></label>
														<input
															type="text"
															className="form-control input-with-label"
															id="contact_name"
															placeholder="Project Manager"
															value={fields.contact_name ? fields.contact_name.value : ""}
															onChange={(e) => this.handleFormInputs(e, 'contact_name')}
														/>
														<span className="error text-danger">{errors["contact_name"]}</span>
													</div>
												</div>
												<div className="col-md-6 col-xl-4">
													<div className="form-group mb-3">
														<label className="mb-1 form-label" htmlFor="ticket_number3">{translate("subcontractor.email")}<span className="error text-danger">*</span></label>
														<input
															type="text"
															className="form-control input-with-label"
															id="email"
															placeholder="Email"
															value={fields.email ? fields.email.value : ""}
															onChange={(e) => this.handleFormInputs(e, 'email')}
														/>
														<span className="error text-danger">{errors["email"]}</span>
													</div>
												</div>
												<div className="col-md-6 col-xl-6">
													<div className="form-group mb-3">
														<label className="mb-1 form-label" htmlFor="ticket_number3">{translate("subcontractor.phone")}<span className="error text-danger">*</span></label>
														<input
															type="text"
															placeholder="Phone"
															className="form-control input-with-label"
															data-bs-backdrop="static"
															value={fields.mobile_number ? fields.mobile_number.value: ""}
															onChange={(e) => this.handleFormInputs(e, 'mobile_number')}
														/>
														<span className="error text-danger">{errors["mobile_number"]}</span>
													</div>
												</div>
												<div className="col-md-6 col-xl-6">
													<div className="form-group mb-3">
														<label className="mb-1 form-label" htmlFor="ticket_number3">{translate("subcontractor.request_date")}<span className="error text-danger">*</span></label>
														<DatePicker
															className="form-control input-with-label"
															selected={fields.request_date && fields.request_date.value ? fields.request_date.value :null}
															onChange={(date) => {this.setState({ fields : {...fields, ...{request_date:{value: date} }} }) }}
															placeholderText={translate("settings.select_request_date")}
															dropdownMode="select"
															showMonthDropdown
															showYearDropdown
														/>
														<span className="error text-danger">{errors["request_date"]}</span>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div className="col-md-12">
										<span className="fields-label-text">{translate("subcontractor.job_details")}</span>
										<div className="fields-label">
											<div className="row">
												<div className="col-md-6 col-xl-4">
													<div className="form-group mb-3 form-check-group">
														<label className="mb-1 form-label" htmlFor="ticket_number3">{translate("subcontractor.is_job_number")}<span
															className="error text-danger">*</span></label>
														<div className="form-group checkbox-row employee-checkbox-row">
															<div className="form-check form-check-inline custom-checkbox">
																<input className="form-check-input" type="radio" name="is_job_number" id="is_job_numberyes" value={RADIO_YES} onChange={(e) => this.handleFormInputs(e, "is_job_number")} defaultChecked={fields.is_job_number && fields.is_job_number.value === String(RADIO_YES) ? true :false}/>
																<label className="form-check-label" htmlFor="is_job_numberyes">
																{translate('calendar.yes')}
																</label>
															</div>
															<div className="form-check form-check-inline custom-checkbox">
																<input className="form-check-input" type="radio" name="is_job_number" id="is_job_numberno" value={RADIO_NO} onChange={(e) => this.handleFormInputs(e, "is_job_number")} defaultChecked={fields.is_job_number && fields.is_job_number.value === String(RADIO_NO) ? true :false} />
																<label className="form-check-label" htmlFor="is_job_numberno">
																{translate('calendar.no')}
																</label>
															</div>
														</div>
													</div>
												</div>
												{(fields.is_job_number && fields.is_job_number.value === String(ACTIVE)) &&
													<div className="col-md-6 col-xl-4">
														<div className="form-group mb-3 custom-form-group">
															<label className="mb-1 form-label" htmlFor="ticket_number3">{translate("subcontractor.job_number")}<span className="error text-danger">*</span></label>
															<Select
																className="custom-react-select"
																value={fields.job_number  ? fields.job_number : null}
																options={jobNumberList}
																onChange={this.handleChange("job_number")}
																closeMenuOnSelect={true}
																placeholder={translate('calendar.select_job_number')}
															/>
															<span className="error text-danger">{errors["job_number"]}</span>
														</div>
													</div>
												}
												<div className="col-md-6 col-xl-4">
													<div className="form-group mb-3">
														<label className="mb-1 form-label" htmlFor="ticket_number3">{translate("subcontractor.project_name")}</label>
														<input
															type="text"
															className="form-control input-with-label"
															id="project_name"
															placeholder="Project Name"
															value={fields.project_name ? fields.project_name.value : ""}
															onChange={(e) => this.handleFormInputs(e, 'project_name')}
														/>
														<span className="error text-danger">{errors["project_name"]}</span>
													</div>
												</div>
												<div className="col-md-6 col-xl-4">
													<div className="form-group mb-3">
														<label className="mb-1 form-label" htmlFor="ticket_number3">{translate("subcontractor.estimated_start_date")}<span className="error text-danger">*</span></label>
														<DatePicker
															className="form-control input-with-label"
															selected={fields.estimated_start_date && fields.estimated_start_date.value ? fields.estimated_start_date.value :null}
															onChange={(date) => {this.setState({ fields : {...fields, ...{estimated_start_date:{value: date} }} }) }}
															placeholderText={translate("settings.select_estimated_start_date")}
															dropdownMode="select"
															showMonthDropdown
															showYearDropdown
														/>
														<span className="error text-danger">{errors["estimated_start_date"]}</span>
													</div>
												</div>
												<div className="col-md-6 col-xl-4">
													<div className="form-group mb-3 custom-form-group">
														<label className="mb-1 form-label" htmlFor="ticket_number3">{translate("subcontractor.service_type")}<span className="error text-danger">*</span></label>
														<Select
															className="custom-react-select"
															placeholder={<label htmlFor={"service_type"}>{translate("Select Service Type")}</label>}
															options={serviceTypeList}
															value={fields["service_type"] ? fields["service_type"] : null}
															closeMenuOnSelect={true}
															onChange={this.handleChange("service_type")}
															isMulti
														/>
														<span className="error text-danger">{errors["service_type"]}</span>
														<span className="error text-danger">{errors["service_type_err"]}</span>
													</div>
												</div>
												<div className="col-md-6 col-xl-4">
													<div className="form-group mb-3 custom-form-group">
														<label className="mb-1 form-label" htmlFor="ticket_number3">{translate("subcontractor.requestor_name")}<span className="error text-danger">*</span></label>
														<Select
															className="custom-react-select"
															placeholder={<label htmlFor={"requestor_name"}>{translate("Select Requestor Name")}</label>}
															options={[]}
															value={fields["requestor_name"] ? fields["requestor_name"] : null}
															closeMenuOnSelect={true}
															onChange={this.handleChange("requestor_name")}
															isDisabled={true}
														/>
														<span className="error text-danger">{errors["requestor_name"]}</span>
													</div>
												</div>
												<div className="col-md-6 col-xl-4">
													<div className="form-group mb-3 custom-form-group">
														<label className="mb-1 form-label" htmlFor="ticket_number3">{translate("subcontractor.department")}<span className="error text-danger">*</span></label>
														<Select
															className="custom-react-select"
															placeholder={<label htmlFor={"department"}>Select Department</label>}
															options={DEPARTMENT_DROPDOWN}
															value={fields["department"] ? fields["department"] : null}
															closeMenuOnSelect={true}
															onChange={this.handleChange("department")}
														/>
														<span className="error text-danger">{errors["department"]}</span>
													</div>
												</div>
												{/* <div className="col-md-6 col-xl-4">
													<div className="form-group mb-3 custom-form-group">
														<label className="mb-1 form-label" htmlFor="ticket_number3">{translate("subcontractor.department_manager")}<span className="error text-danger">*</span></label>
														<Select
															className="custom-react-select"
															placeholder={<label htmlFor={"department_manager"}>{translate("Select Department Manager")}</label>}
															options={userArr}
															value={fields["department_manager"] ? fields["department_manager"] : null}
															closeMenuOnSelect={true}
															onChange={this.handleChange("department_manager")}
															isMulti
														/>
														<span className="error text-danger">{errors["department_manager"]}</span>
														<span className="error text-danger">{errors["department_manager_err"]}</span>
													</div>
												</div> */}
												<div className="col-md-12">
													<div className="form-group mb-3">
														<label className="mb-1 form-label" htmlFor="ticket_number3">{translate("subcontractor.justification")}<span className="error text-danger">*</span></label>
														<textarea type="justification" className="form-control" name='justification' id='justification' value={fields["justification"] ? fields["justification"]["value"] : ""} placeholder='Enter Justification' onChange={(event) => this.handleFormInputs(event, "justification")}></textarea>
														<span className="error text-danger">{errors["justification"]}</span>
													</div>
												</div>

											</div>

										</div>

									</div>
								</div>
								<div className="comman-submit-btn">
									<div className="left-box d-flex flex-wrap align-items-center">
										<button type="submit" className="btn btn-fill">Submit </button>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
			<div className="modal-backdrop fade show"></div>


		</>
	}
}
export default AddSubContratorForm;