import React, { Component } from "react";
import { callBackendAPIAndImageUpload, reFormatSuccess, handleFormInputs } from './../../helpers/common';
import { STATUS_SUCCESS } from './../../config/global_constants';
import { translate } from './../../helpers/intl_helpers';
import downloadIcon from '../../assets/images/download_pdf_icon.svg';
import TooltipElement from '../calendar/TooltipElement';

export default class BulkEmployee extends Component {

	constructor(props) {
		super(props)

		this.state = {
			errors		 : {},
			fields		 : {},
			submitted	 : false,
			bulkErrors	 : null,
		};

		this.closeModal 		= this.closeModal.bind(this);
		this.handleFormInputs 	= this.handleFormInputs.bind(this);
		this.handleUpdateEmployeeFormSubmit = this.handleUpdateEmployeeFormSubmit.bind(this);
	}


	/** For get employee details  */
	componentDidMount() {
		window.document.body.classList.add("modal-open");
		window.document.body.classList.add("overflow-hidden");
	}

	/**
	* For manage form submit event
	*
	* @param e 	as 	current form object
	*
	* @return null
	*/
	handleUpdateEmployeeFormSubmit(e) {
		e.preventDefault();

		const { image } = this.state.fields;

		this.setState({
			submitted: true,
		}, async () => {

			if (image === "" || image === undefined) return this.setState({ errors: { "image": translate("employees.please_select_file") }, submitted: false });

			/** Set form data */
			let formData = new FormData();
			if (image) formData.append("employee_file", this.state.fields.image.value);

			/** Submit form to server */
			let responseData = await callBackendAPIAndImageUpload({ model: 'employee', method: 'importEmployees', fileData: formData, formData: {} }, true);

			if (responseData.data.status === STATUS_SUCCESS) {
				/** Show success flash message **/
				reFormatSuccess(responseData.data.message[0].msg);

				/** Remove disable attribute from submit button */
				this.setState({ errors: {}, fields: {}, submitted: false });

				/** Close the modal */
				this.closeModal()
			} else {

				let employeeErrorsObj 	=	responseData.data.message;
				let employeeErrors		=	"";

				if(Array.isArray(employeeErrorsObj)){
					employeeErrors=responseData.data.message[0].msg;
				}else if(employeeErrorsObj.constructor === Object){
					Object.keys(employeeErrorsObj).map(row=>{
						employeeErrors += `<br/> Row ${row + " <br/>" + employeeErrorsObj[row]} <br/>`;
						return null;
					});
				}else if(employeeErrorsObj.constructor === String){
					employeeErrors += employeeErrorsObj;
				}

				/** Remove disable attribute from submit button */
				this.setState({ submitted: false, bulkErrors: employeeErrors });
			}
		});
	}// end handleUpdateEmployeeFormSubmit()

	/**
	* For update state fields values
	*
	* @param e  	as current field object
	* @param field  as current field name
	*
	* @return null
	*/
	handleFormInputs(e, field) {

		let response = handleFormInputs(e, field, this.state.fields);
		this.setState({ response });
	}// end handleFormInputs()

	/**
	* For hide model
	*
	* @return null
	*/
	closeModal() {
		window.document.body.classList.remove("modal-open");
		window.document.body.classList.remove("overflow-hidden");
		if (this.props.hideModal) this.props.hideModal();
	}// end closeModal()


	render() {
		const { submitted, fields, errors,bulkErrors } = this.state;

		return <>
			<div className={"modal fade show d-block view-modal employee-edit"} tabIndex={-1} aria-labelledby="adduserModal" aria-hidden="true">
				<div className="modal-dialog  modal-lg">
					<div className="modal-content">
						<button type="button" className="btn-close" onClick={() => { this.closeModal() }}>X {translate("system.close")}</button>
						<div className="modal-body">
							<h3 className="modal-title">{translate("employees.add_bulk_employee")}</h3>
							<div className="adduser-form">

								<form className="adduser-form" onSubmit={(event) => this.handleUpdateEmployeeFormSubmit(event, 'updateEmployee')} noValidate>

								{bulkErrors ?
									<div className="row m-1 mb-2">
										<div className="col-12 bg-danger color-white pb-2" dangerouslySetInnerHTML={{__html: bulkErrors ? bulkErrors :""}} ></div>
									</div>
								:null}

									<div className="row mb-4">
										<div className='col-12'>
											<div className="form-group custom-upload">
												<span className="form-label d-block">
													Image
												</span>
												<div className="d-flex align-items-center upload_source_box">
													<label className="upload-source">
														<input type="file" name='file' id="file" className="d-none" onChange={(event) => this.handleFormInputs(event, "image")} />
														<span>{translate("system.upload")}</span>
														<p>
															{fields.image && fields.image.file_name
																?
																fields.image.file_name
																:
																<>
																	{translate("system.select_file")}
																	<i className="error text-danger">*</i>
																</>
															}
														</p>
													</label>
													<TooltipElement title={translate("system.download_sample_file")} placement='bottom' >
														<a href="/sampleFiles/employee.csv" className="download_file" download>
															<img src={downloadIcon} alt="img"/>
														</a>
													</TooltipElement>
												</div>
											</div>
											<span className="error">{errors["image"]}</span>
										</div>
									</div>

									<button type={submitted ? "button" : "submit"} className="btn btn-fill" >
										{translate("system.submit")} {submitted && <i className="spinner-border spinner-border-sm  ms-3" />}
									</button>
									<div className="table-responsive employee-code-table">
										<table class="table table-bordered">
											<thead>
												<tr>
													<th scope="col" colspan="6"> asterisk (<span className="error text-danger">*</span>) mark label is required</th>
												</tr>
											</thead>
											<tbody>
												<tr>
													<td>{translate("employee.employee_number")} <span className="error text-danger">*</span></td>
													<td>{translate("employee.first_name")}</td>
													<td>{translate("employee.last_name")}</td>
												</tr>
												<tr>
													<td>{translate("employee.email")}</td>
													<td>{translate("employee.mobile_number")}</td>
													<td>{translate("employee.permission_level")}</td>
												</tr>
												<tr>
													<td>{translate("employee.job_title")}</td>
													<td>{translate("employee.job_title_type")}</td>
													<td>{translate("employee.driver_tier_level")}</td>
												</tr>
												<tr>
													<td>{translate("employee.company_name")} </td>
													<td>{translate("employee.location")}</td>
													<td>{translate('calendar.department')}</td>
												</tr>
												<tr>
													<td>{translate("employee.assigned_equipment")}</td>
													<td>{translate("employee.equipment_code")}</td>
													<td>{translate("employee.hire_date")}</td>
												</tr>
												<tr>
													<td>{translate("employee.termination_date")}</td>
													<td>{translate("employee.display_on_calendar")}</td>
													<td>{translate('skill_set.skill_set')}</td>
												</tr>
												<tr>
													<td>{translate("employee.bpid")}</td>
													<td></td>
													<td></td>
												</tr>
											</tbody>
										</table>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="modal-backdrop fade show"></div>
		</>
	}
}