import React, {Component} from 'react'
import {PropTypes} from 'prop-types'

import { translate } from '../../../helpers/intl_helpers';
import wrenchImg from '../../../assets/images/wrench.png';

class AssetItem extends Component
{
    constructor(props) {
        super(props);
        this.state = {};
      }

    static propTypes = {
        task: PropTypes.object.isRequired,
    }

    render(){
        const {task, isDragging, connectDragSource, connectDragPreview, moveItem, setAssetItem} = this.props;

        let dragContent = <li className="assets-list-wrap cursor-pointer" key={task.id} style={{ backgroundColor: task.state.bgColor }}  >
                <img src={task.state.image} alt="assets" onClick={() => setAssetItem(task,"asset")}  />
                <div>
                <span onClick={() => setAssetItem(task,"asset")} dangerouslySetInnerHTML={{__html: task.name ? task.name :""}}></span>
                <span>{task.state && task.state.driver_owner_name ? <small>({task.state.driver_owner_name})</small> : null}</span>
                </div>
                <button className="box-remove-btn" onClick={(e) => moveItem(e,task.id,"asset")} title={translate("calendar.move_to_shop")}>
                    <img src={wrenchImg} alt="img"/>
                </button>
            </li>;

        return (
            isDragging ? null : (
            <>
                {connectDragPreview(
                    connectDragSource(dragContent)
                )}
            </>
            )
        )
    }
}
export default AssetItem