import React, { Component } from "react";
import { translate  } from '../../helpers/intl_helpers';
import { upperCaseOfName  } from '../../helpers/common';
import { AGREEMENT_TYPE_DROPDOWN, ADMIN, DISPATCHER  } from '../../config/global_constants';
import { NavLink as Link} from "react-router-dom";

import Auth from './../../helpers/Auth';

import callIcon 	from '../../assets/images/call-icon.svg';
import mailIcon 	from '../../assets/images/mail-icon.svg';
import editIcon 	from '../../assets/images/edit-icon.svg';
import deleteIcon 	from '../../assets/images/delete-icon.svg';

export default class SubcontractorDetailModal extends Component {
	constructor(props){
        super(props)
        this.state = {};
    }

	/**
	* For hide model
	*
	* @return null
	*/
	closeModal(){
        window.closeModal('subcontractorDetailModal');
	}// end closeModal()

	/** Function to handle image lightbox */
	handleImageClick = (imageUrl) => {
		this.setState({
			lightBoxShow	: true,
			selectedImage	: imageUrl
		});
	}


	render() {
		const { profileDetails, fileUrl, noImgUrl, toggleModal, handleDeleteEmployee } = this.props;

		return (
			<>
			<div className="modal fade crew-modal" id="subcontractorDetailModal" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
				<div className="modal-dialog modal-xl">
					<div className="modal-content">
						<button type="button" className="btn-close" onClick={()=> {this.closeModal()} }>X Close</button>

						<div className="modal-body">
							<h3>{profileDetails.full_name ? profileDetails.full_name : translate('calendar.details')}</h3>
							<div className="list-group list-group-flush">
								<ul className="details-listing">
									<li>
										<div className="profile-detail-box">
											<span className={`sort-name ${(!profileDetails.image) ? 'greenbg' : ''}`}>
												{profileDetails && profileDetails.image ? (
													<img src={`${fileUrl}${profileDetails.image}`} alt="employee" onError={(event) => event.target.src = noImgUrl} onClick={() => this.handleImageClick(`${fileUrl}${profileDetails.image}`)} />
												) : (
													upperCaseOfName(profileDetails.first_name ? profileDetails.first_name : "", profileDetails.last_name ? profileDetails.last_name : "")
												)}
											</span>
											<div>
												<div className="inner-wrap">
													<p>{translate('employee.status')}</p>
													<span className={profileDetails.active ? "text-success" : "text-danger"}>
													{profileDetails.active ? "Active" : "Deactive"}
												</span>
												</div>
											</div>
										</div>
									</li>
									<li>
										<div className="inner-wrap">
											<p>{translate('subcontractor.contact_name')}</p>
											<span>{profileDetails.contact_name ? profileDetails.contact_name : "N/A"}</span>
										</div>
									</li>
									<li>
										<div className="inner-wrap">
											<p>{translate('subcontractor.email')}</p>
											<div>
												<img src={mailIcon} alt="img" width={19} height={21} />
												{profileDetails.email ? (
													<a href={`mailto:${profileDetails.email}`} className="text-lowercase email-link">
														{profileDetails.email}
													</a>
												) : "N/A"}
											</div>
										</div>
									</li>
									<li>
										<div className="inner-wrap">
											<p>{translate('subcontractor.mobile')}</p>
											<div>
												<img src={callIcon} alt="img" width={19} height={21} />
												{profileDetails.mobile ? (
													<a href={`tel:${profileDetails.mobile}`}>{profileDetails.mobile}</a>
												) : "N/A"}
											</div>
										</div>
									</li>
									<li>
										<div className="inner-wrap">
											<p>{translate('subcontractor.project_manager')}</p>
											<span>{profileDetails.project_manager ? profileDetails.project_manager : "N/A"}</span>
										</div>
									</li>
									<li>
										<div className="inner-wrap">
											<p>{translate('subcontractor.vendor_code')}</p>
											<span>{profileDetails.vendor_code ? profileDetails.vendor_code : "N/A"}</span>
										</div>
									</li>
									<li>
										<div className="inner-wrap">
											<p>{translate('subcontractor.street')}</p>
											<span>{profileDetails.street ? profileDetails.street : "N/A"}</span>
										</div>
									</li>
									<li>
										<div className="inner-wrap">
											<p>{translate('subcontractor.city')}</p>
											<span>{profileDetails.city ? profileDetails.city : "N/A"}</span>
										</div>
									</li>
									<li>
										<div className="inner-wrap">
											<p>{translate('subcontractor.state')}</p>
											<span>{profileDetails.state ? profileDetails.state : "N/A"}</span>
										</div>
									</li>
									<li>
										<div className="inner-wrap">
											<p>{translate('subcontractor.zip')}</p>
											<span>{profileDetails.zip ? profileDetails.zip : "N/A"}</span>
										</div>
									</li>
									<li>
										<div className="inner-wrap">
											<p>{translate('subcontractor.flagging_company')}</p>
											<span>{profileDetails.is_flagger ? "Yes" : "No"}</span>
										</div>
									</li>
									<li>
										<div className="inner-wrap">
											<p>{translate('subcontractor.agreement_type')}</p>
											<span>{AGREEMENT_TYPE_DROPDOWN.find(item => item.value === profileDetails.agreement_type)?.label || "N/A"}</span>
										</div>
									</li>
								</ul>
							</div>
							{([ADMIN, DISPATCHER].indexOf(Auth.getPermission()) !== -1) &&
								<div className="detail-modal-editbtns">
									<Link to="#" onClick={() => toggleModal(true, profileDetails._id)}>
										<img src={editIcon} alt="img" width={21} height={21} />
										Edit
									</Link>
								</div>
							}
						</div>
					</div>
				</div>
			</div>
			</>
		)
	}
}
